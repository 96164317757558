import React, { useState, useEffect, useContext } from "react";
import API from "../../services/axios/Requests";
import { UserContext } from '../../hooks/UserContext'; // Importa o contexto do usuário
import {
  Card,
  CardBody,
  CardTitle,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner
} from "reactstrap";
import { useTranslation } from 'react-i18next';

const Grid = () => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    name: '',
    nickname: '',
    email: '',
    wallet_address: '',
    password: '' // A senha será limpa por segurança
  });
  const [confirmPassword, setConfirmPassword] = useState('');
  const [modal, setModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const user = useContext(UserContext); // Obtém o contexto do usuário

  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchUserData = async () => {
    try {
      setIsLoading(true);
      const response = await API.get(`user/${user.id}`);
     
      const { name, nickname, email, wallet_address } = response.data;
      setFormData({
        name,
        nickname,
        email,
        wallet_address,
        password: ''
      });
      setIsLoading(false);
    } catch (error) {
      console.error(t('grid.fetchUserDataError'), error);
      setModalMessage(JSON.stringify(error));
      setModal(true);
    }
  };

  const validatePassword = (password) => {
    const errors = [];
    if (password.length < 8) {
      errors.push(t('grid.passwordLengthError'));
    }
    if (!/[A-Z]/.test(password)) {
      errors.push(t('grid.passwordUppercaseError'));
    }
    if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
      errors.push(t('grid.passwordSpecialCharError'));
    }
    return errors;
  };

  const updateUserData = async () => {
    try {
      let dataToSend = { ...formData };
      if (!dataToSend.password) {
        delete dataToSend.password;
      }
      await API.put(`user/${user.id}`, dataToSend);
      setModalMessage(t('grid.updateSuccess'));
      setModal(true);
    } catch (error) {
      console.error(t('grid.updateUserDataError'), error);
      setModalMessage(t('grid.updateError'));
      setModal(true);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const passwordErrors = validatePassword(formData.password);
    if (formData.password !== confirmPassword) {
      passwordErrors.push(t('grid.passwordMismatch'));
    }
    if (passwordErrors.length > 0) {
      setModalMessage(passwordErrors.join('\n'));
      setModal(true);
      return;
    }
    await updateUserData();
  };

  const toggleModal = () => {
    setModal(!modal);
  };

  return (
    <div>
      <Card>
        <CardTitle tag="h2" className="border-bottom p-3 mb-0">
          <i className='bi bi-pencil-square' style={{ fontSize: '2rem', marginRight: '1rem' }}></i>
          {t('grid.editProfile')}
        </CardTitle>
        <CardBody>
          {isLoading ? (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
              <Spinner type="grow" color="danger" />
            </div>
          ) : (
            <Form onSubmit={handleSubmit}>
              <FormGroup>
                <Label for="name"><b>{t('grid.name')}</b></Label>
                <Input
                  id="name"
                  name="name"
                  placeholder={t('grid.namePlaceholder')}
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </FormGroup>
              <FormGroup>
                <Label for="nickname"><b>{t('grid.nickname')}</b></Label>
                <Input
                  id="nickname"
                  name="nickname"
                  placeholder={t('grid.nicknamePlaceholder')}
                  value={formData.nickname}
                  onChange={handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="email"><b>{t('grid.email')}</b></Label>
                <Input
                  type="email"
                  id="email"
                  name="email"
                  placeholder={t('grid.emailPlaceholder')}
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </FormGroup>
              <FormGroup>
                <Label for="wallet_address"><b>{t('grid.walletAddress')}</b></Label>
                <Input
                  id="wallet_address"
                  name="wallet_address"
                  placeholder={t('grid.walletAddressPlaceholder')}
                  value={formData.wallet_address}
                  onChange={handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="password"><b>{t('grid.password')}</b></Label>
                <Input
                  type="password"
                  id="password"
                  name="password"
                  placeholder="xxxxxxxxxxxxx"
                  value={formData.password}
                  onChange={handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="confirmPassword"><b>{t('grid.confirmPassword')}</b></Label>
                <Input
                  type="password"
                  id="confirmPassword"
                  name="confirmPassword"
                  placeholder="xxxxxxxxxxxxx"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </FormGroup>
              <Button type="submit">{t('grid.saveChanges')}</Button>
            </Form>
          )}
        </CardBody>
      </Card>
      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>{t('grid.message')}</ModalHeader>
        <ModalBody>
          {modalMessage.split('\n').map((message, index) => (
            <p key={index}>{message}</p>
          ))}
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={toggleModal}>{t('grid.close')}</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default Grid;
