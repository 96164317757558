import { Col, Row, Card, CardBody, CardTitle, Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import React, { useState, useEffect, useContext } from "react";
import SalesChart from "../components/dashboard/SalesChart";
import Feeds from "../components/dashboard/Feeds";
import ProjectTables from "../components/dashboard/ProjectTable";
import TopCards from "../components/dashboard/TopCards";
import CardsDetail from "../components/dashboard/CardsDetail";
import API from "../services/axios/Requests";
import { UserContext } from '../hooks/UserContext'; // Importa o contexto do usuário
import { useTranslation } from 'react-i18next';

const Starter = () => {
  const { t } = useTranslation();
  const user = useContext(UserContext); // Obtém o contexto do usuário
  const [walletValue, setWalletValue] = useState(null); 
  const [showAlert, setShowAlert] = useState(false);
  const [chartData, setChartData] = useState([]);

  const handleSave = async (data) => {
    setChartData([
      { name: "Commodities", data: data.commoditiesData },
      { name: "Forex", data: data.forexData },
      { name: "Crypto", data: data.cryptoData }
    ]);

    // Aqui você pode fazer uma chamada de API para salvar os dados no banco
    try {
      await API.post("/chart-data", data);
      console.log("Dados salvos com sucesso!");
    } catch (error) {
      console.error("Erro ao salvar os dados:", error);
    }
  };

 // Função para buscar o valor da carteira do usuário
 const fetchWalletValue = async () => {
  if (user && user.id) {
    try {
      const response = await API.get(`/wallet/${user.id}`);
      setWalletValue(response.data.wallet.income);
      // Verifique se o valor da carteira é 0 e exiba o modal de alerta
      if (response.data.wallet.quantity === 0) {
        setShowAlert(true);
      } else {
        setShowAlert(false);
      } 
    } catch (error) {
      console.error("Erro ao buscar o valor da carteira: ", error);
    }
  } else {
    console.error("User ID não disponível");
  }
};

useEffect(() => {
  fetchWalletValue();
}, [user.id]);

  return (
    <div>
      {/* Modal de alerta */}
      <Modal isOpen={showAlert} toggle={() => setShowAlert(!showAlert)}>
        <ModalHeader toggle={() => setShowAlert(!showAlert)}>{t('starter.alertTitle')}</ModalHeader>
        <ModalBody>
          <div>
            <p style={{ textAlign: "center" }}>
              <b>{t('starter.noInvestmentBalance')}</b> <br />
              {t('starter.addFundsToInvest')}
            </p>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={() => window.location.href = "/deposit"}>{t('starter.addFundsButton')}</Button>
        </ModalFooter>
      </Modal>

      {/***Top Cards***/}
      <Row>
        <Col sm="6" lg="6">
          <CardsDetail
            bg="bg-dark-success text-white"
            title={t('starter.balance')}
            subtitle={t('starter.yearlyEarning')}
            earning={walletValue !== null ? `${Number(walletValue).toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 })} USDT` : '...'}
            icon="bi bi-wallet"
            to="/wallet"
          />
        </Col>
        <Col sm="6" lg="6">
          <CardsDetail
            bg="bg-dark-success text-white"
            title={t('starter.investYourBalance')}
            subtitle={t('starter.refundGiven')}
            earning={t('starter.newInvestment')}
            icon="bi bi-arrow-up-right"
            to="/invest"
          />
        </Col>
      </Row>

      {/***Sales & Feed***/}
      <Row>
        <Col xxl="12">
          <SalesChart chartData={chartData} />
        </Col>
      </Row>

      {/***Table ***/}
      <Row>
        <Col lg="7" xxl="8" md="12">
          <Card>
            <CardTitle tag="h5" className="border-bottom p-3 mb-0">
              <i className='bi bi-card-list' style={{fontSize: '2rem', marginRight: '1rem'}} ></i> {t('starter.transactionHistory')}
            </CardTitle>
            <ProjectTables />
          </Card>
        </Col>
        <Col md="12" lg="5" xxl="4">
          <Feeds />
        </Col>
      </Row>
    </div>
  );
};

export default Starter;
