import React, { useState, useEffect, useContext } from "react";
import { UserContext } from '../../hooks/UserContext';
import API from "../../services/axios/Requests";
import { useTranslation } from 'react-i18next';
import {
  Card,
  CardBody,
  CardTitle,
  ListGroup,
  CardSubtitle,
  ListGroupItem,
  Button,
} from "reactstrap";

const Feeds = () => {
  const { t } = useTranslation();
  const [notifications, setNotifications] = useState([]);
  const user = useContext(UserContext);

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await API.get(`/notification/${user.id}`);
        console.log("Notificações: ", response.data);
        setNotifications(response.data);
      } catch (error) {
        console.error("Erro ao buscar notificações:", error);
      }
    };

    fetchNotifications();
  }, [user.id]);

  return (
    <Card>
      <CardBody>
        <CardTitle tag="h5">{t('feeds.notifications')}</CardTitle>
        <CardSubtitle className="mb-2 text-muted" tag="h6">
          {t('feeds.latestUpdates')}
        </CardSubtitle>
        <ListGroup flush className="mt-4">
          {notifications.map((notification, index) => (
            <ListGroupItem
              key={index}
              action
              href="/"
              tag="a"
              className="d-flex align-items-center p-3 border-0"
            >
              <Button
                className="rounded-circle me-3"
                size="sm"
                color="danger"
              >
                <i className="bi bi-bell"></i>
              </Button>
              {notification.description}
            </ListGroupItem>
          ))}
        </ListGroup>
      </CardBody>
    </Card>
  );
};

export default Feeds;
