import React, { useState } from "react";
import { Col, Row, Button, Input, FormGroup, Label, Card, CardBody, CardTitle } from "reactstrap";
import TopCards from "../../components/dashboard/TopCards";
import ProjectTable from "../../components/dashboard/ProjectTable";
import { useTranslation } from 'react-i18next';

const Wallet = () => {
  const { t } = useTranslation();
  const [wallet, setWallet] = useState(0);
  const [received, setReceived] = useState(0);
  const [transfered, setTransfered] = useState(0);
  const [convert, setConvert] = useState(0);
  const [searchTerm, setSearchTerm] = useState(''); 
  const [dados, setDados] = useState([
    { id: 1, transacao: t('wallet.addFunds'), valor: 'R$100', data: '2023-03-25' },
    { id: 2, transacao: t('wallet.withdrawFunds'), valor: 'R$50', data: '2023-03-26' },
    { id: 3, transacao: t('wallet.internalTransfer'), valor: 'R$200', data: '2023-03-27' },
    // Adicione mais dados conforme necessário
  ]);

  return (
    <div className="wallet">
      {/***Top Cards***/}    
      <Row>          
        <Col sm="12" lg="4">
          <TopCards
            bg="bg-black text-white"
            title={t('wallet.addFunds')}
            subtitle={t('wallet.addFunds')}
            earning={t('wallet.addFunds')}
            icon="bi bi-arrow-up-right"
            to="/deposit"
          />
        </Col>
        <Col sm="12" lg="4">
          <TopCards
            bg="bg-black text-white"
            title={t('wallet.withdraw')}
            subtitle={t('wallet.withdraw')}
            earning={t('wallet.requestWithdrawal')}
            icon="bi bi-arrow-down-left"
            to="/withdraw"
          />
        </Col>
        <Col sm="12" lg="4">
          <TopCards
            bg="bg-black text-white"
            title={t('wallet.transfer')}
            subtitle={t('wallet.transfer')}
            earning={t('wallet.transfer')}
            icon="bi bi-arrow-left-right"
            to="/transfer"
          />
        </Col>
      </Row>

      {/* DataTable */}
      <div className="custom-table">
        <Row>
          <Col sm="12">
            <Card>
              <CardTitle tag="h5" className="border-bottom p-3 d-flex align-items-center">
                <i className='bi bi-card-list' style={{fontSize: '2rem', marginRight: '1rem'}}></i>{t('wallet.transactionHistory')}
              </CardTitle>
              <CardBody>  
                <ProjectTable />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Wallet;
