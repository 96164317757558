import React from 'react';
import { Container, Row, Col, Card, CardBody, CardTitle } from 'reactstrap';
import TabelaIndication from "../../components/dashboard/TabelaIndication";
import { useTranslation } from 'react-i18next';

const Gerente = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Row className=" mt-3 d-flex align-items-center mb-4">
        <Col className=" d-flex align-items-center"> 
          <i className='bi bi-award text-white'  style={{fontSize: '2rem', marginRight: '1rem'}} ></i>
          <h2 className="text-white">{t('gerente.becomeGlobalManager')}</h2>
        </Col>
        <p className="text-white">{t('gerente.receiveBenefits')}</p>
        <p className="text-white">{t('gerente.checkRequirements')}</p>
      </Row>
      {/* Seção de Cards */}
      <Row>
        <Col md="3">
          <Card>
            <CardBody className='d-flex align-items-center' style={{marginBottom: '-0.6rem'}}>
              <div>
                <i className='bi bi-currency-dollar' style={{fontSize: '2rem', marginRight: '10px'}}></i>
              </div>
              <CardTitle tag="h6" className="mb-0">{t('gerente.investment')}</CardTitle>
            </CardBody>
          </Card>
        </Col>
        <Col md="3">
          <Card>
            <CardBody className='d-flex align-items-center'>
              <div>
                <i className='bi bi-people' style={{fontSize: '2rem', marginRight: '10px'}}></i>
              </div>
              <CardTitle tag="h6" className="mb-0">{t('gerente.referFriends')}</CardTitle>
            </CardBody>
          </Card>
        </Col>
        <Col md="3">
          <Card>
            <CardBody className='d-flex align-items-center'>
              <div>
                <i className='bi bi-patch-check' style={{fontSize: '2rem', marginRight: '10px'}}></i>
              </div>
              <CardTitle tag="h6" className="mb-0">{t('gerente.activeAccount')}</CardTitle>
            </CardBody>
          </Card>
        </Col>
        <Col md="3">
          <Card>
            <CardBody className='d-flex align-items-center'>
              <div>
                <i className='bi bi-building-check' style={{fontSize: '2rem', marginRight: '10px'}}></i>
              </div>
              <CardTitle tag="h6" className="mb-0">{t('gerente.cleanRecord')}</CardTitle>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Card>
        <CardTitle tag="h2" className="border-bottom p-3 mb-0">
          <i className='bi bi-card-list' style={styles.title}></i> {t('gerente.indications')}
        </CardTitle>
        <CardBody>
          <TabelaIndication />
        </CardBody>
      </Card>
    </Container>
  );
};

const styles = {
  title: {
    fontSize: '2rem',
    marginRight: '1rem'
  }
};

export default Gerente;
