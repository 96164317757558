import { Col, Row, Card, CardBody, CardTitle } from "reactstrap";
import ProjectTable from "../../components/dashboard/ProjectTable";
import { useTranslation } from 'react-i18next';

export const Transactions = () => {
  const { t } = useTranslation();

  return (
    <Card>
      <CardTitle tag="h2" className="border-bottom p-3 mb-0">
        <i className='bi bi-card-list' style={styles.title}></i> {t('transactions.transactionHistory')}
      </CardTitle>
      <CardBody>
        <ProjectTable />
      </CardBody>
    </Card>
  );
};

const styles = {
  title: {
    fontSize: '2rem',
    marginRight: '1rem'
  }
};

export default Transactions;
