import React, { useState, useContext } from "react";
import qr_code from '../../assets/images/carteira/qr_code.png';
import API from "../../services/axios/Requests";
import { UserContext } from '../../hooks/UserContext';
import { NumericFormat } from 'react-number-format';
import {
  Card,
  Alert,
  Row,
  Col,
  CardTitle,
  CardBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  Spinner,
  InputGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import { useTranslation } from 'react-i18next';

const Deposit = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [walletOrigin, setWalletOrigin] = useState("");
  const [quantity, setQuantity] = useState("");
  const [modal, setModal] = useState(false);
  const walletHash = "TR7gwyTkXwfbSiMcvR89dQCpSvkJPy6UrV";
  const [errorMessage, setErrorMessage] = useState([]);
  const user = useContext(UserContext);

  const copyWalletHashToClipboard = () => {
    navigator.clipboard.writeText(walletHash).then(
      () => console.log(t('deposit.copiedToClipboard')),
      (err) => console.error(t('deposit.copyFailed'), err)
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const numericQuantity = parseFloat(quantity);
    const minimumInvestment = user.country === 'BR' ? 1010 : 2010;

    if (numericQuantity < minimumInvestment) {
      setLoading(false);
      setErrorMessage([t('deposit.minimumInvestmentError', { amount: minimumInvestment })]);
      return;
    }

    const dataToSend = new FormData();
    dataToSend.append('wallet_origin', walletOrigin);
    dataToSend.append('quantity', quantity);
    dataToSend.append('type_of', 'add_fundos');
    dataToSend.append('user_id', user.id);

    try {
      await API.post('/transaction', dataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setModal(true);
      setWalletOrigin("");
      setQuantity("");
      setErrorMessage([]);
    } catch (error) {
      setLoading(false);
      if (error.response && error.response.data && error.response.data.errors) {
        const errors = error.response.data.errors;
        const errorMessages = Object.keys(errors).flatMap(key => errors[key]);
        setErrorMessage(errorMessages);
      } else {
        setErrorMessage([t('deposit.submitError')]);
      }
    }
  };

  const toggleModal = () => setModal(!modal);

  const MoneyIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-currency-dollar" viewBox="0 0 16 16">
      <path d="M4 10.781c.148 1.667 1.513 2.85 3.591 3.003V15h1.043v-1.216c2.27-.179 3.678-1.438 3.678-3.3 0-1.59-.947-2.51-2.956-3.028l-.722-.187V3.467c1.122.11 1.879.714 2.07 1.616h1.47c-.166-1.6-1.54-2.748-3.54-2.875V1H7.591v1.233c-1.939.23-3.27 1.472-3.27 3.156 0 1.454.966 2.483 2.661 2.917l.61.162v4.031c-1.149-.17-1.94-.8-2.131-1.718zm3.391-3.836c-1.043-.263-1.6-.825-1.6-1.616 0-.944.704-1.641 1.8-1.828v3.495l-.2-.05zm1.591 1.872c1.287.323 1.852.859 1.852 1.769 0 1.097-.826 1.828-2.2 1.939V8.73z"/>
    </svg>
  );

  const CopyIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-copy copiar" viewBox="0 0 16 16" onClick={copyWalletHashToClipboard}>
      <path fillRule="evenodd" d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"/>
    </svg>
  );

  return (
    <Row>
      <Col>
        <Card>
          <CardTitle tag="h2" className="border-bottom p-3 mb-0">
            <i className='bi bi-arrow-up-right' style={{fontSize: '2rem', marginRight: '1rem'}}></i>
            {t('deposit.addFunds')}
          </CardTitle>
          <CardBody>
            {errorMessage.length > 0 && (
              <Alert color="danger">
                <ul>
                  {errorMessage.map((msg, index) => (
                    <li key={index}>{msg}</li>
                  ))}
                </ul>
              </Alert>
            )}
            <Form onSubmit={handleSubmit}>
              <FormGroup className="mb-4">
                <Label for="walletInput"><b>{t('deposit.transactionId')}</b></Label>
                <InputGroup>
                  <Input 
                    id="wallet_origin"
                    name="wallet_origin"
                    placeholder={t('deposit.transactionIdPlaceholder')}
                    type="text"
                    bsSize="lg"
                    value={walletOrigin}
                    onChange={(e) => setWalletOrigin(e.target.value)}
                  />
                </InputGroup>
              </FormGroup>

              <FormGroup className="mb-4">
                <Label for="quantityInput">
                  <b>{t('deposit.amount')}</b> - 
                  {user.country === 'BR' ? (
                    <span className="text-alpha"> {t('deposit.minimumInvestmentBR')}</span>
                  ) : (
                    <span className="text-alpha"> {t('deposit.minimumInvestment')}</span>
                  )}
                </Label>
                <InputGroup>
                  <NumericFormat
                    id="quantity"
                    name="quantity"
                    placeholder={t('deposit.amountPlaceholder')}
                    className="form-control"
                    value={quantity}
                    onValueChange={(values) => {
                        const { value } = values;
                        setQuantity(value);
                    }}
                    thousandSeparator="."
                    decimalSeparator=","
                    allowNegative={false}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    isNumericString={true}
                  />
                  <Button>
                    <MoneyIcon />
                  </Button>
                </InputGroup>
                <FormText>
                  {t('deposit.enterAmount')}
                </FormText>
              </FormGroup>

              <FormGroup>
                <Label><b>{t('deposit.walletAddress')}</b> <i className="text-alpha">- {t('deposit.account')}</i></Label>
                <div className="d-flex align-items-center qr_code">
                  <img src={qr_code} alt="Wallet QR Code" style={{ maxWidth: "100px", marginRight: "15px" }}/>
                  <span>{walletHash}</span>
                  <CopyIcon/>
                </div>
                <FormText>
                  {t('deposit.scanQRCode')}
                </FormText>
              </FormGroup>

              <Button type="submit" className="mt-2" color="success" size="lg">
                {loading ? <Spinner size="sm" /> : t('deposit.confirm')}
              </Button>
            </Form>
          </CardBody>
        </Card>
      </Col>

      {/* Modal de confirmação */}
      <Modal isOpen={modal} toggle={toggleModal} backdrop="static" keyboard={false}>
        <ModalHeader toggle={toggleModal}>{t('deposit.confirmation')}</ModalHeader>
        <ModalBody>
          {t('deposit.confirmationMessage')}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => { toggleModal(); window.location.href = "/transactions"; }}>{t('deposit.close')}</Button>
        </ModalFooter>
      </Modal>
    </Row>
  );
};

export default Deposit;
