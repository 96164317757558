import React, { useState, useEffect } from 'react';
import { Card, CardBody, CardTitle, Table, Spinner } from 'reactstrap';
import API from "../../services/axios/Requests";
import { useTranslation } from 'react-i18next';

const IndicacaoAdm = () => {
  const { t } = useTranslation();
  const [indications, setIndications] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    API.get('/indications')
      .then(response => {
        setIndications(response.data);
        setLoading(false);
      })
      .catch(error => {
        console.error(t('indicacaoAdm.fetchError'), error);
        setLoading(false);
      });
  }, [t]);

  return (
    <Card>
      <CardTitle tag="h2" className="border-bottom p-3 mb-0">
        <i className='bi bi-card-list' style={{ fontSize: '2rem', marginRight: '1rem' }}></i>
        {t('indicacaoAdm.title')}
      </CardTitle>
      <CardBody>
        {loading ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <Spinner type="grow" color="danger" />
          </div>
        ) : indications.length > 0 ? (
          <Table className="no-wrap mt-3 align-middle" responsive borderless>
            <thead>
              <tr>
                <th>#</th>
                <th>{t('indicacaoAdm.referrerUser')}</th>
                <th>{t('indicacaoAdm.referredUser')}</th>
                <th>{t('indicacaoAdm.date')}</th>
              </tr>
            </thead>
            <tbody>
              {indications.map((indication, index) => (
                <tr key={index} className="border-top">
                  <td className="pt-3 pb-3">{index + 1}</td>
                  <td className="pt-3 pb-3">{indication.referrer_name}</td>
                  <td className="pt-3 pb-3">{indication.referred_nickname}</td>
                  <td className="pt-3 pb-3">
                    {new Date(indication.indication_date).toLocaleDateString('pt-BR')}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <div style={{ textAlign: 'center', marginTop: 20 }}>{t('indicacaoAdm.noIndications')}</div>
        )}
      </CardBody>
    </Card>
  );
}

export default IndicacaoAdm;
