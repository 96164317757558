import React, { useEffect, useState } from "react";
import { Card, CardBody, Table, Spinner, Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useTranslation } from 'react-i18next';
import API from "../../services/axios/Requests"; // Assumindo que você já tem isso configurado

const TabelaUser = () => {
  const { t } = useTranslation();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [confirmModal, setConfirmModal] = useState({ isOpen: false, email: null, status: null, user_id: null , nome : null });
  const [userInfoModal, setUserInfoModal] = useState({ isOpen: false, user: null });
  const [transactions, setTransactions] = useState([]);
  const [modalLoading, setModalLoading] = useState(false);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await API.get('/users/all');
        console.log(response.data);
        setUsers(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Erro ao buscar transações: ", error);
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  const openConfirmModal = (email, status, user_id , nome) => {
    setConfirmModal({ isOpen: true, email: email, status: status, user_id: user_id , nome : nome});
  };
  

  const closeConfirmModal = () => {
    setConfirmModal({ ...confirmModal, isOpen: false });
    window.location.reload();
  };

  const confirmAction = async () => {
    try {
      const { email, status, user_id , nome } = confirmModal;
      await API.post(`/users/approve`, { email: email, status: status, user_id: user_id  , nome : nome});
      const updatedUsers = users.map(user => {
        if (user.email === email) {
          user.status = status;
        }
        return user;
      });
      setUsers(updatedUsers);
      closeConfirmModal();
    } catch (error) {
      console.error("Erro ao atualizar usuário: ", error);
    }
  };

  const openUserInfoModal = async (user) => {
    setUserInfoModal({ isOpen: true, user: user });
    try {
      setModalLoading(true); // Ativar indicador de carregamento
      const response = await API.get(`/transactions/user/${user.id}`);
      setTransactions(response.data);
      setModalLoading(false); // Desativar indicador de carregamento após o carregamento dos dados
    } catch (error) {
      console.error("Erro ao buscar transações do usuário: ", error);
      setModalLoading(false); // Certifique-se de desativar o indicador de carregamento em caso de erro
    }
  };

  const closeUserInfoModal = () => {
    setUserInfoModal({ ...userInfoModal, isOpen: false });
    setTransactions([]); // Limpar transações ao fechar o modal
  };

  if (userInfoModal.user && userInfoModal.user.data_income) {
    // Divide a string de data e hora em partes separadas
    const parts = userInfoModal.user.data_income.split(' ');

    // Separa a parte da data
    const datePart = parts[0];

    // Converte a parte da data em um objeto Date
    const dateObject = new Date(datePart);

    // Verifica se a conversão foi bem-sucedida e exibe a data formatada
    if (!isNaN(dateObject.getTime())) {
        const formattedDate = dateObject.toLocaleDateString('pt-BR');
        console.log('Data de Rendimento:', formattedDate);
    } else {
        console.error('Erro ao converter data');
    }
}
  

  return (
    <div>
      <Card>
        <CardBody>
          {loading ? (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
              <Spinner type="grow" color="danger" />
            </div>
          ) : (
            <Table className="no-wrap mt-3 align-middle" responsive borderless>
              <thead>
                <tr>
                  <th>{t('userTable.user')}</th>
                  <th>{t('userTable.email')}</th>
                  <th>{t('userTable.wallet_address')}</th>
                  <th>{t('userTable.date')}</th>
                  <th>{t('userTable.status')}</th>
                  <th>{t('userTable.actions')}</th>
                </tr>
              </thead>
              <tbody>
                {users.map((user, index) => (
                  <tr key={index} className="border-top">
                    <td className="pt-3 pb-3">
                      <div className="d-flex align-items-center">
                        <strong> {user.name}</strong>
                      </div>
                    </td>
                    <td className="pt-3 pb-3">
                      <div className="d-flex align-items-center">
                        <strong> {user.email}</strong>
                      </div>
                    </td>
                    <td className="pt-3 pb-3">{user.wallet_address}</td>
                    <td className="pt-3 pb-3">
                      {new Date(user.created_at).toLocaleDateString('pt-BR')}
                    </td>

                    <td className="pt-3 pb-3">
                      {user.status === 1 ? (
                        <i className="bi bi-check-circle-fill text-success"></i>
                      ) : user.status === 0 ? (
                        <i className="bi bi-hourglass-split text-danger"></i>
                      ) : (
                        <i className="bi bi-exclamation-circle-fill text-warning"></i>
                      )}
                    </td>

                    <td className="pt-3 pb-3">
                      <Button className="mb-1" title={t('userTable.approve')} color="success" disabled={user.status === 1}
                        onClick={() => openConfirmModal(user.email, 1, user.id , user.nickname)}><i className="bi bi-check-circle-fill"></i></Button><br></br>
                      <Button className="mb-1" title={t('userTable.reject')} color="danger" disabled={user.status === 0}
                        onClick={() => openConfirmModal(user.email, 0, user.id, user.nickname)}><i className="bi bi-x-circle-fill"></i></Button><br></br>
                      <Button title={t('userTable.view')} color="info"
                        onClick={() => openUserInfoModal(user)}><i className="bi bi-eye-fill"></i></Button>
                    </td>

                  </tr>
                ))}
              </tbody>

            </Table>
          )}
        </CardBody>
      </Card>

      <Modal isOpen={confirmModal.isOpen} toggle={closeConfirmModal}>
        <ModalHeader toggle={closeConfirmModal}>{t('userTable.confirmation')}</ModalHeader>
        <ModalBody>
          {t('userTable.confirm_action')}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={confirmAction}>{t('userTable.confirm')}</Button>
          <Button color="secondary" onClick={closeConfirmModal}>{t('userTable.cancel')}</Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={userInfoModal.isOpen} toggle={closeUserInfoModal}>
        <ModalHeader toggle={closeUserInfoModal}>{t('userTable.user_details')}</ModalHeader>
        <ModalBody>
          
          <p><strong>{t('userTable.name')}:</strong> {userInfoModal.user && userInfoModal.user.name}</p>
          <p><strong>{t('userTable.email')}:</strong> {userInfoModal.user && userInfoModal.user.email}</p>
          <p><strong>{t('userTable.wallet_address')}:</strong> {userInfoModal.user && userInfoModal.user.wallet_address}</p>
          <p><strong>{t('userTable.date')}:</strong> {userInfoModal.user && new Date(userInfoModal.user.created_at).toLocaleDateString('pt-BR')}</p>
          <p><strong>{t('userTable.status')}:</strong>
            {userInfoModal.user && userInfoModal.user.status === 1 ? (
              <span> {t('userTable.active')} <i className="bi bi-check-circle-fill text-success"></i></span>
            ) : userInfoModal.user && userInfoModal.user.status === 0 ? (
              <span>{t('userTable.inactive')} <i className="bi bi-hourglass-split text-danger"></i> </span>
            ) : (
              <span><i className="bi bi-exclamation-circle-fill text-warning"></i> {t('userTable.other_status')}</span>
            )}</p>
          <p><strong>{t('userTable.investment_value')}:</strong> {`${userInfoModal.user && userInfoModal.user.quantity.toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 })} USDT`} </p>
          <p><strong>{t('userTable.income_value')}:</strong> {`${userInfoModal.user && userInfoModal.user.income.toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 })} USDT`}</p>
          <p><strong>{t('userTable.income_date')}:</strong> {userInfoModal.user && userInfoModal.user.data_income  ? new Date(userInfoModal.user.data_income).toLocaleDateString('pt-BR') : ''}
</p>


          <Table  className="no-wrap mt-3 align-middle" responsive >
            <thead>
              <tr>
                <th>{t('userTable.date')}</th>
                <th>{t('userTable.type')}</th>
                <th>{t('userTable.origin')}</th>
                <th>{t('userTable.destination')}</th>
                <th>{t('userTable.amount')}</th>
                <th>{t('userTable.status')}</th>
              </tr>
            </thead>
            <tbody>
              {transactions.map((transaction, index) => (
                console.log(transaction.name),
                <tr key={index}>
                  <td>{new Date(transaction.created_at).toLocaleDateString('pt-BR')}</td>
                  <td>{transaction.status === 1 && transaction.name === 'Solicitou Saque' ? (
                        t('userTable.withdrawal_completed')
                      ) : transaction.status === 0 && transaction.name === 'Solicitou Saque' ? (
                        t('userTable.withdrawal_request')
                      ) : transaction.status === 1 && transaction.name === 'Solicitou Saque de Investimento' ? (
                        t('userTable.investment_withdrawal_completed')
                      ) : transaction.status === 0 && transaction.name === 'Solicitou Saque de Investimento' ? (
                        t('userTable.investment_withdrawal_request')
                      ) : (
                        transaction.name
                      )}</td>
                  <td>{transaction.wallet_origin}</td>
                  <td>{transaction.wallet_dest}</td>
                  <td> {`${transaction.quantity.toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 })} USDT`}</td>
                  <td> {transaction.status === 1 ? (
                    <i className="bi bi-check-circle-fill text-success"></i>
                  ) : transaction.status === 0 ? (
                    <i className="bi bi-hourglass-split text-danger"></i>
                  ) : (
                    <i className="bi bi-exclamation-circle-fill text-warning"></i>
                  )}</td>
                </tr>
              ))}
            </tbody>
          </Table>

        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={closeUserInfoModal}>{t('userTable.close')}</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default TabelaUser;
