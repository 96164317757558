
import axios from 'axios';



const API = axios.create({
    // baseURL: 'http://api_alphabull.test/api/',
   baseURL: 'https://api.alphabullmarket.com/api',
    
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    }

  });

export default API;