import React, { useState, useEffect } from 'react';
import { Col, Row, Card, CardBody, CardTitle, Form, FormGroup, Label, Input, Button, Spinner, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import API from "../../services/axios/Requests";
import { useTranslation } from 'react-i18next';

const Notification = () => {
  const { t } = useTranslation();
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState('');
  const [description, setDescription] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    API.get('/users/all')
      .then(response => {
        setUsers(response.data);
        setLoading(false);
      })
      .catch(error => {
        console.error(t('notification.fetchError'), error);
        setLoading(false);
      });
  }, [t]);

  const handleSubmit = (e) => {
    e.preventDefault();
    
    const payload = {
      description: description
    };
  
    if (selectedUser === "all") {
      payload.user_ids = users.map(user => user.id); // Envia um array de IDs de todos os usuários
    } else {
      payload.user_id = selectedUser; // Envia apenas o ID do usuário selecionado
    }
  
    API.post('/send-notification', payload)
      .then(response => {
        if (response.data.success) {
          setMessage(t('notification.success'));
          setSelectedUser('');
          setDescription('');
        } else {
          setMessage(t('notification.failure'));
        }
        setShowAlert(true);
      })
      .catch(error => {
        console.error(t('notification.sendError'), error);
        setMessage(t('notification.sendError'));
        setShowAlert(true);
      });
  }
  
  return (
    <Card>
      <CardTitle tag="h2" className="border-bottom p-3 mb-0">
        <i className='bi bi-card-list' style={{ fontSize: '2rem', marginRight: '1rem' }}></i>
        {t('notification.title')}
      </CardTitle>
      <CardBody>
        {loading ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <Spinner type="grow" color="danger" />
          </div>
        ) : (
          <Form onSubmit={handleSubmit} className='mt-3 mb-5'>
            <FormGroup>
              <Label for="userSelect" className="font-weight-bold">{t('notification.selectUser')}</Label>
              <Input type="select" name="select" id="userSelect" value={selectedUser} onChange={(e) => setSelectedUser(e.target.value)}>
                <option value="">{t('notification.select')}</option>
                <option value="all">{t('notification.allUsers')}</option> 
                {users.map(user => (
                  <option key={user.id} value={user.id}>{user.name}</option>
                ))}
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="message" className="font-weight-bold">{t('notification.message')}</Label>
              <Input type="textarea" name="text" id="message" value={description} onChange={(e) => setDescription(e.target.value)} />
            </FormGroup>
            <Button type="submit" color="primary">{t('notification.send')}</Button>
          </Form>
        )}
        <Modal isOpen={showAlert} toggle={() => setShowAlert(!showAlert)}>
          <ModalHeader toggle={() => setShowAlert(!showAlert)}>{t('notification.alert')}</ModalHeader>
          <ModalBody>
            <div>
              <p style={{ textAlign: "center" }}>
                <b>{message}</b>
              </p>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="success" onClick={() => { setShowAlert(false); window.location.reload(); }}>{t('notification.ok')}</Button>
          </ModalFooter>
        </Modal>
      </CardBody>
    </Card>
  );
}

export default Notification;
