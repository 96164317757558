import { Card, CardBody, CardTitle } from "reactstrap";
import TabelaIncome from "../../components/dashboard/TabelaIncome";
import { useTranslation } from 'react-i18next';

export const Income = () => {
  const { t } = useTranslation();

  return (
    <Card>
      <CardTitle tag="h2" className="border-bottom p-3 mb-0">
        <i className='bi bi-card-list' style={{fontSize: '2rem', marginRight: '1rem'}}></i>{t('income.earnings')}
      </CardTitle>
      <CardBody>
        <TabelaIncome />
      </CardBody>
    </Card>
  );
};

export default Income;
