import React, { useState, useContext } from 'react';


import logo from "../../assets/images/logos/logo_alpha.png"; // Ajuste para o caminho correto da imagem do logo
import API from "../../services/axios/Requests";

import UserContext from '../../hooks/UserContext'; // Importa o contexto do usuário

import { Spinner, Modal, ModalBody } from 'reactstrap';  // Import Spinner instead of Loader



function Login() {

 
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [isLoading, setIsLoading] = useState(false);  // State to manage loading state
  const [modalText, setModalText] = useState('');  // State to manage loading state
  const user = useContext(UserContext); // Obtém o contexto do usuário

  const handleLogin = async () => {
    setIsLoading(true); // Ativa o indicador de carregamento
    const userData = { email, password };
  
    try {
      const response = await API.post('autentication', userData);

  
      if (response.status === 200) {
        await localStorage.setItem('token', response.data.token);
        await localStorage.setItem('user', JSON.stringify(response.data.user));
        await localStorage.setItem('role', response.data.role);
        await localStorage.setItem('id', response.data.id);
        await localStorage.setItem('country', response.data.country);
        if (response.data.role === 'administrator') {
          window.location.href = '/dashboardadm'; // Redireciona para o painel do administrador após o login
        } else if (response.data.role === 'subscriber') {
        window.location.href = '/starter'; // Redireciona para a página inicial após o login
      } else {
        throw new Error('A resposta não foi 200 OK');
      } }
    } catch (error) {
      setIsLoading(false); // Desativa o indicador de carregamento
      const errorMessage = error.response?.data?.message || "Erro desconhecido ao tentar login";
      setModalText(errorMessage); // Configura o texto do modal com a mensagem de erro
  
      // Exibir o modal de erro por um curto período antes de ocultar novamente
      setTimeout(() => {
        setModalText('');
      }, 5000); // Oculta o modal após 5 segundos
    }
  };
  

  return (
    <>


<Modal isOpen={!!modalText || isLoading} centered>
  <ModalBody className="text-center">
    {isLoading ? (
      <Spinner color="primary" />
    ) : (
      <>
        {modalText || 'Login Bem Sucedido!'}
      </>
    )}
  </ModalBody>
</Modal>

    

    <div style={styles.container}>
      <div style={styles.card}>
        <img src={logo} alt="Logo" style={styles.logo} />
        <h5 style={styles.title}>LOGIN</h5>
        <h3 style={styles.title}>Área do Cliente</h3>
        <input type="text" placeholder="Email" style={styles.input} value={email} onChange={(e)=>{setEmail(e.target.value)}} />
        <input type="password" placeholder="Senha" style={styles.input} value={password} onChange={(e)=>{setPassword(e.target.value)}}  />
        <button style={styles.button} onClick={handleLogin} >Entrar</button>
        <div style={styles.dividerContainer}>
          <hr style={styles.divider} />
          <span style={styles.orText}></span>
          <hr style={styles.divider} />
        </div>
        
      </div>
    </div>
    </>
  );
}

const styles = {
  container: {
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#121212',
    fontFamily: 'Arial, sans-serif'
  },
  card: {
    backgroundColor: '#232323',
    padding: '40px',
    borderRadius: '8px',
    boxShadow: '0 20px 50px rgba(0, 0, 0, 0.5)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: 'white'
  },
  logo: {
    width: '300px',
    marginBottom: '30px'
  },
  title: {
    margin: '0 0 20px 0'
  },
  input: {
    width: '300px',
    padding: '15px',
    margin: '10px 0',
    borderRadius: '4px',
    border: 'none'
  },
  button: {
    backgroundColor: '#DB0006',
    color: 'white',
    padding: '10px 50px',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '16px',
    fontWeight: 'bold',
    textTransform: 'uppercase'
  },
  dividerContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    margin: '20px 0'
  },
  divider: {
    flex: 1,
    height: '1px',
    backgroundColor: '#3a3a3a'
  },
  orText: {
    padding: '0 10px'
  },
  socialButton: {
    backgroundColor: '#232323',
    color: 'white',
    padding: '10px',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '16px',
    margin: '10px 0',
    width: '100%',
    textAlign: 'left',
    boxShadow: '0 3px 6px rgba(0, 0, 0, 0.2)'
  },
  signupText: {
    marginTop: '20px',
    cursor: 'pointer'
  }
};

export default Login;
