import React, { useState } from "react";
import { Card, CardBody, FormGroup, Label, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter, InputGroup, InputGroupText } from "reactstrap";
import { useTranslation } from 'react-i18next';

const ChartForm = ({ onSave }) => {
  const { t } = useTranslation();
  const [category, setCategory] = useState("");
  const [month, setMonth] = useState("");
  const [value, setValue] = useState("");
  const [modal, setModal] = useState(false);

  const toggleModal = () => setModal(!modal); // Função para alternar a visibilidade do modal

  const handleSubmit = () => {
    toggleModal(); // Abrir o modal para confirmação
  };

  const handleConfirm = () => {
    onSave({ category, month, value });
    toggleModal(); // Fechar o modal

    // Adiciona um atraso de 2 segundos antes de recarregar a página
    setTimeout(() => {
        window.location.reload();
    }, 2000); // 2000 milissegundos = 2 segundos
  };

  return (
    <Card>
      <CardBody>
        <Label tag="h5">{t('chartForm.insertIncome')}</Label>
        <FormGroup>
          <Label for="category">{t('chartForm.category')}</Label>
          <Input
            type="select"
            name="category"
            id="category"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
          >
            <option value="">{t('chartForm.selectCategory')}</option>
            <option value="Commodities">{t('chartForm.commodities')}</option>
            <option value="Forex">{t('chartForm.forex')}</option>
            <option value="Crypto">{t('chartForm.crypto')}</option>
          </Input>
        </FormGroup>
        <FormGroup>
          <Label for="month">{t('chartForm.month')}</Label>
          <Input
            type="select"
            name="month"
            id="month"
            value={month}
            onChange={(e) => setMonth(e.target.value)}
          >
            <option value="">{t('chartForm.selectMonth')}</option>
            <option value="Jan">{t('months.jan')}</option>
            <option value="Feb">{t('months.feb')}</option>
            <option value="Mar">{t('months.mar')}</option>
            <option value="Apr">{t('months.apr')}</option>
            <option value="May">{t('months.may')}</option>
            <option value="Jun">{t('months.jun')}</option>
            <option value="Jul">{t('months.jul')}</option>
            <option value="Aug">{t('months.aug')}</option>
            <option value="Sep">{t('months.sep')}</option>
            <option value="Oct">{t('months.oct')}</option>
            <option value="Nov">{t('months.nov')}</option>
            <option value="Dec">{t('months.dec')}</option>
          </Input>
        </FormGroup>
        <FormGroup>
          <Label for="value">{t('chartForm.value')}</Label>
          <InputGroup>
            <Input
              type="text"
              name="value"
              id="value"
              placeholder={t('chartForm.enterValue')}
              value={value}
              onChange={(e) => setValue(e.target.value)}
            />
            <InputGroupText>%</InputGroupText>
          </InputGroup>
        </FormGroup>

        {/* Modal de confirmação */}
        <Modal isOpen={modal} toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}>{t('chartForm.confirmData')}</ModalHeader>
          <ModalBody>
            {t('chartForm.income')}: <b>{value}% </b><br></br>
            {t('chartForm.month')}: <b>{month}</b><br></br>
            {t('chartForm.asset')}: <b>{category}</b> 
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={handleConfirm}>{t('chartForm.confirm')}</Button>
            <Button color="secondary" onClick={toggleModal}>{t('chartForm.cancel')}</Button>
          </ModalFooter>
        </Modal>

        <Button color="primary" onClick={handleSubmit}>{t('chartForm.save')}</Button>
      </CardBody>
    </Card>
  );
};

export default ChartForm;
