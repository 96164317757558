import React, { useState, useEffect, useContext } from "react";
import { Link } from 'react-router-dom';
import API from "../../services/axios/Requests";
import { NumericFormat } from 'react-number-format';
import { UserContext } from '../../hooks/UserContext'; // Importa o contexto do usuário
import {
  Card,
  Alert,
  Row,
  Col,
  CardTitle,
  CardBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  Spinner,
  InputGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { useTranslation } from 'react-i18next';

const Invest = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [quantity, setQuantity] = useState(""); // Adiciona estado para o valor do input
  const [modal, setModal] = useState(false); // Adiciona estado para controle do modal
  const [walletValue, setWalletValue] = useState(null); 
  const [errorMessage, setErrorMessage] = useState("");
  const user = useContext(UserContext); // Obtém o contexto do usuário

  // Função para buscar o valor da carteira do usuário
  const fetchWalletValue = async () => {
    try {
      const response = await API.get(`/wallet/${user.id}`); 
      setWalletValue(response.data.wallet.income);
    } catch (error) {
      console.error(t('invest.fetchWalletError'), error);
    }
  };

  useEffect(() => {
    fetchWalletValue(); // Chamando a função para buscar o valor da carteira ao montar o componente
  }, [t, user.id]);

  const Loading = () => (
    <Spinner size="lg">{t('invest.loading')}</Spinner>
  );

  const MoneyIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-currency-dollar" viewBox="0 0 16 16">
      <path d="M4 10.781c.148 1.667 1.513 2.85 3.591 3.003V15h1.043v-1.216c2.27-.179 3.678-1.438 3.678-3.3 0-1.59-.947-2.51-2.956-3.028l-.722-.187V3.467c1.122.11 1.879.714 2.07 1.616h1.47c-.166-1.6-1.54-2.748-3.54-2.875V1H7.591v1.233c-1.939.23-3.27 1.472-3.27 3.156 0 1.454.966 2.483 2.661 2.917l.61.162v4.031c-1.149-.17-1.94-.8-2.131-1.718zm3.391-3.836c-1.043-.263-1.6-.825-1.6-1.616 0-.944.704-1.641 1.8-1.828v3.495l-.2-.05zm1.591 1.872c1.287.323 1.852.859 1.852 1.769 0 1.097-.826 1.828-2.2 1.939V8.73z"/>
    </svg>
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (parseFloat(quantity) > parseFloat(walletValue)) {
      setErrorMessage(t('invest.exceedBalanceError'));
      return; // Interrompe o envio do formulário
    }

    setLoading(true);
    const dataToSend = new FormData();
    dataToSend.append('quantity', quantity);
    dataToSend.append('user_id', user.id);

    try {
      await API.post(`/wallet/${user.id}`, dataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setLoading(false);
      setModal(true); // Abre o modal de confirmação
      setQuantity("");
      setErrorMessage("");
    } catch (error) {
      setLoading(false);
      console.error(t('invest.submitError'), error);
      setErrorMessage(t('invest.requiredFieldError'));
    }
  };

  // Função para alternar a visibilidade do modal
  const toggleModal = () => setModal(!modal);

  const toggleModalClose = () => {
    setModal(!modal);
    window.location.reload(); // Recarrega a página ao fechar o modal
  };

  return (
    <Row>
      <Col>
        <Card>
          <CardTitle tag="h2" className="border-bottom p-3 mb-0">
            <i className='bi bi-arrow-up-right' style={{fontSize: '2rem', marginRight: '1rem'}}></i> {t('invest.investBalance')}
          </CardTitle>
          <CardBody>
            {errorMessage && (
              <Alert color="danger">{errorMessage}</Alert>
            )}
            <Form onSubmit={handleSubmit}>
              <input type="hidden" name="type_of" value="add_fundos" />
              <Col sm="12" lg="4">
                <FormGroup className="mb-4">
                  <Label for="quantityInput"><b>{t('invest.amountToInvest')}</b></Label>
                  <InputGroup>
                    <NumericFormat
                      id="quantity"
                      name="quantity"
                      placeholder={t('invest.amountPlaceholder')}
                      className="form-control"
                      value={quantity}
                      onValueChange={(values) => {
                        const { value } = values;
                        // Atualiza o estado com o valor não formatado
                        setQuantity(value);
                      }}
                      thousandSeparator="."
                      decimalSeparator=","
                      allowNegative={false}
                      decimalScale={2} // Permite até dois dígitos após o ponto decimal
                      fixedDecimalScale={true} // Aplica sempre duas casas decimais
                      isNumericString={true}
                    />
                    <Button>
                      <MoneyIcon />
                    </Button>
                  </InputGroup>
                  <FormText>
                    {t('invest.totalBalance')}: <strong>{walletValue !== null ? `${Number(walletValue).toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 })} USDT` : '...'}</strong>
                  </FormText>
                </FormGroup>
              </Col>
              <Col sm="12" lg="12">
                <FormGroup check>
                  <Label check>
                    <Input type="checkbox" />{' '}
                    {t('invest.agreement')}
                  </Label>
                </FormGroup>
              </Col>
              <Button type="submit" color="success" className="mt-5" size="md">
                {loading ? <Spinner size="sm" /> : null} {t('invest.confirm')}
              </Button>
              <Link to="/deposit" style={{ textDecoration: 'none', marginLeft: 10 }}>
                <Button className="mt-5" color="dark" size="md">
                  {t('invest.addFromExternalWallet')}
                </Button>
              </Link>
            </Form>
          </CardBody>
        </Card>
      </Col>
      {/* Modal de confirmação */}
      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>{t('invest.confirmation')}</ModalHeader>
        <ModalBody>
          {t('invest.successMessage')}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => { toggleModal(); window.location.href = "/starter"; }}>{t('invest.close')}</Button>
        </ModalFooter>
      </Modal>
    </Row>
  );
};

export default Invest;
