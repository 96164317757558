import React from 'react';
import { Container, Row, Col, Card, CardBody, CardTitle, CardText, Table } from 'reactstrap';
import { useTranslation } from 'react-i18next';

const Indicacao = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Row className="mt-3 d-flex align-items-center mb-4">
        <Col className="d-flex align-items-center"> 
          <i className='bi bi-gift text-white' style={{fontSize: '2rem', marginRight: '1rem'}}></i>
          <h2 className="text-white">{t('indicacao.inviteFriends')}</h2>
        </Col>
      </Row>
      {/* Seção de Cards */}
      <Row>
        <Col md="4">
          <Card>
            <CardBody>
              <div className="circle-box lg-box d-inline-block text-white mb-4">
                <i className='bi bi-person' style={{fontSize: '2rem'}}></i>
              </div>
              <CardTitle tag="h5">{t('indicacao.step1')}</CardTitle>
              <CardText className='mb-5'>{t('indicacao.step1Text')}</CardText>
            </CardBody>
          </Card>
        </Col>
        <Col md="4">
          <Card>
            <CardBody>
              <div className="circle-box lg-box d-inline-block text-white mb-4">
                <i className='bi bi-send' style={{fontSize: '1.5rem'}}></i>
              </div>
              <CardTitle tag="h5">{t('indicacao.step2')}</CardTitle>
              <CardText>{t('indicacao.step2Text')}</CardText>
            </CardBody>
          </Card>
        </Col>
        <Col md="4">
          <Card>
            <CardBody>
              <div className="circle-box lg-box d-inline-block text-white mb-4">
                <i className='bi bi-gift' style={{fontSize: '2rem'}}></i>
              </div>
              <CardTitle tag="h5">{t('indicacao.step3')}</CardTitle>
              <CardText className='mb-4'>{t('indicacao.step3Text')}</CardText>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row className="d-flex align-items-center mb-4">
        <Col className="d-flex align-items-center"> 
          <i className='bi bi-book text-white' style={{fontSize: '2rem', marginRight: '1rem'}}></i>
          <h3 className="text-white">{t('indicacao.rules')}</h3>
        </Col>
      </Row>
      
      <Col md="12">
        <Card>
          <CardBody>
            <Table bordered responsive>
              <thead>
                <tr>
                  <th>{t('indicacao.firstDeposit')}</th>
                  <th>{t('indicacao.secondDeposit')}</th>
                  <th>{t('indicacao.friendAddsFunds')}</th>
                  <th>{t('indicacao.eachReceives')}</th>
                  <th>{t('indicacao.friendInvests')}</th>
                  <th>{t('indicacao.eachReceives')}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>USDT $100</td>
                  <td>{t('indicacao.friendInvestsMin')}</td>
                  <td>USDT $500</td>
                  <td>$100</td>
                  <td>USDT $4010 {t('indicacao.orMore')}</td>
                  <td>$250</td>
                </tr>
              </tbody>
            </Table>
          </CardBody>
        </Card>
      </Col>
    </Container>
  );
};

export default Indicacao;
