import React, { useEffect, useState } from "react";
import { Card, CardBody, Table, Spinner, Button, Modal, ModalBody, ModalFooter, ModalHeader, Input, FormGroup, Label, InputGroup, InputGroupText } from "reactstrap";
import API from "../../services/axios/Requests";
import { useTranslation } from 'react-i18next';

const TabelaIncome = () => {
  const { t } = useTranslation();
  const [wallets, setWallets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editModal, setEditModal] = useState({
    isOpen: false,
    wallet: null
  });

  useEffect(() => {
    const fetchTransactions = async () => {
      setLoading(true);
      try {
        const response = await API.get('/wallet-all');
        setWallets(response.data);
      } catch (error) {
        console.error(t("tableincome.fetchError"), error);
      } finally {
        setLoading(false);
      }
    };

    fetchTransactions();
  }, [t]);

  const openEditModal = (wallet) => {
    setEditModal({
      isOpen: true,
      wallet: { ...wallet }
    });
  };

  const closeEditModal = () => {
    setEditModal({ ...editModal, isOpen: false });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditModal(prevState => ({
      ...prevState,
      wallet: {
        ...prevState.wallet,
        [name]: value
      }
    }));
  };

  const saveChanges = async () => {
    const { wallet } = editModal;
  
    try {
      const response = await API.put(`/wallet/${wallet.id}`, wallet);
      console.log("Resposta do servidor:", response.data);
  
      setWallets(prevWallets => prevWallets.map(w => w.id === wallet.id ? { ...w, ...wallet } : w));
  
      closeEditModal();
      window.location.reload();
    } catch (error) {
      console.error(t("tableincome.saveError"), error.response ? error.response.data : error.message);
    }
  };

  return (
    <div>
      <Card>
        <CardBody>
          {loading ? (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
              <Spinner type="grow" color="danger" />
            </div>
          ) : (
            <Table className="no-wrap mt-3 align-middle" responsive borderless>
              <thead>
                <tr>
                  <th>{t("tableincome.user")}</th>
                  <th>{t("tableincome.wallet")}</th>
                  <th>{t("tableincome.email")}</th>
                  <th>{t("tableincome.investment")}</th>
                  <th>{t("tableincome.income")}</th>
                  <th>{t("tableincome.lastInvestmentDate")}</th>
                  <th>{t("tableincome.actions")}</th>
                </tr>
              </thead>
              <tbody>
                {wallets.map((wallet, index) => (
                  <tr key={index} className="border-top">
                    <td className="pt-3 pb-3"><strong>{wallet.user_name}</strong></td>
                    <td className="pt-3 pb-3"><strong>{wallet.wallet_address}</strong></td>
                    <td className="pt-3 pb-3"><strong>{wallet.email}</strong></td>
                    <td className="pt-3 pb-3">
                      {`${wallet.quantity.toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 })} USDT`}
                    </td>
                    <td className="pt-3 pb-3">
                      {`${wallet.income.toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 })} USDT`}
                    </td>
                    <td className="pt-3 pb-3">
                      {new Date(wallet.data_income).toLocaleDateString('pt-BR')}
                    </td>
                    <td className="pt-3 pb-3">
                      <Button title={t("tableincome.edit")} color="secondary" onClick={() => openEditModal(wallet)}>
                        <i className="bi bi-pencil-fill"></i>
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </CardBody>
      </Card>
      {/* Modal de edição */}
      <Modal isOpen={editModal.isOpen} toggle={closeEditModal}>
        <ModalHeader toggle={closeEditModal}>{t("tableincome.editData")}</ModalHeader>
        <ModalBody>
          {editModal.wallet && (
            <FormGroup>
              <Label for="user_name">{t("tableincome.user")}</Label>
              <Input type="text" id="user_name" name="user_name" value={editModal.wallet.user_name} onChange={handleInputChange} readOnly />
              <Label for="wallet_address">{t("tableincome.wallet")}</Label>
              <Input type="text" id="wallet_address" name="wallet_address" value={editModal.wallet.wallet_address} onChange={handleInputChange} readOnly />
              <Label for="email">{t("tableincome.email")}</Label>
              <Input type="email" id="email" name="email" value={editModal.wallet.email} onChange={handleInputChange} readOnly />
              <Label for="quantity">{t("tableincome.investment")}</Label>
              <Input type="number" id="quantity" name="quantity" value={editModal.wallet.quantity} onChange={handleInputChange} />
              <Label for="income">{t("tableincome.income")}</Label>
              <Input type="number" id="income" name="income" value={editModal.wallet.income} onChange={handleInputChange} />
              <Label for="percentage">{t("tableincome.incomePercentage")}</Label>
              <InputGroup>
                <Input type="number" id="percentage" name="percentage" value={editModal.wallet.percentage} onChange={handleInputChange} />
                <InputGroupText>%</InputGroupText>
              </InputGroup>
            </FormGroup>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={saveChanges}>{t("tableincome.saveChanges")}</Button>
          <Button color="secondary" onClick={closeEditModal}>{t("tableincome.cancel")}</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default TabelaIncome;
