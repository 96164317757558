import React, { useEffect, useState, useContext } from "react";
import { Card, CardBody, Table, Spinner } from "reactstrap";
import API from "../../services/axios/Requests";
import { UserContext } from '../../hooks/UserContext';
import { useTranslation } from 'react-i18next';

const ProjectTables = () => {
  const { t } = useTranslation();
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const user = useContext(UserContext);

  useEffect(() => {
    const fetchTransactions = async () => {
      setLoading(true); // Garantir que o estado de carregamento seja verdadeiro ao iniciar o fetch
      if (user && user.id) { 
        try {
          const response = await API.get(`/transactions/user/${user.id}`);
          setTransactions(response.data);
        } catch (error) {
          console.error("Erro ao buscar transações: ", error);
        }
      } else {
        console.error("Não há transações disponíveis para o usuário.");
      }
      setLoading(false); // Desativar o estado de carregamento após o fetch
    };

    fetchTransactions();
  }, [user?.id]);  // Adiciona user.id como dependência para re-fetch quando o ID do usuário mudar

  return (
    <div>
      <Card>
        <CardBody>
          {loading ? (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
              <Spinner type="grow" color="danger" />
            </div>
          ) : transactions.length > 0 ? (
            <Table className="no-wrap mt-3 align-middle" responsive borderless>
              <thead>
                <tr>
                  <th>{t('projectTables.transactionType')}</th>
                  <th>{t('projectTables.wallet')}</th>
                  <th>{t('projectTables.status')}</th>
                  <th>{t('projectTables.value')}</th>
                  <th>{t('projectTables.date')}</th>
                </tr>
              </thead>
              <tbody>
                {transactions.map((transaction, index) => (
                  <tr key={index} className="border-top">
                    <td className="pt-3 pb-3">
                      <div className="d-flex align-items-center">
                        <i className={`${transaction?.icon} me-3`}></i>
                        <strong>{transaction?.status === 1 && transaction?.name === 'Solicitou Saque' ? (
                        t('projectTables.withdrawalCompleted')
                      ) : transaction?.status === 0 && transaction?.name === 'Solicitou Saque' ? (
                        t('projectTables.requestedWithdrawal')
                      ) : transaction?.status === 1 && transaction?.name === 'Solicitou Saque de Investimento' ? (
                        t('projectTables.investmentWithdrawalCompleted')
                      ) : transaction?.status === 0 && transaction?.name === 'Solicitou Saque de Investimento' ? (
                        t('projectTables.requestedInvestmentWithdrawal')
                      ) : (
                        transaction?.name
                      )}</strong> 
                      </div>
                    </td>
                    <td className="pt-3 pb-3">
                      <div className="d-flex align-items-center">
                        <strong>
                          {/^\d+$/.test(transaction?.wallet_origin) ? transaction?.wallet_dest : transaction?.wallet_origin}
                        </strong>
                      </div>
                    </td>
                    <td className="pt-3 pb-3">
                      {transaction?.status === 1 ? (
                        <i className="bi bi-check-circle-fill text-success"></i>
                      ) : transaction?.status === 0 ? (
                        <i className="bi bi-hourglass-split text-danger"></i>
                      ) : (
                        <i className="bi bi-exclamation-circle-fill text-warning"></i>
                      )}
                    </td>
                    <td className="pt-3 pb-3">
                      {`${transaction?.quantity.toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 })} USDT`}
                    </td>
                    <td className="pt-3 pb-3">
                      {new Date(transaction?.created_at).toLocaleDateString('pt-BR')}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <div style={{ textAlign: 'center', marginTop: 20 }}>{t('projectTables.noTransactions')}</div>
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default ProjectTables;
