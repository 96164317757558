import React, { useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { UserContext } from '../hooks/UserContext';

function ProtectedRoute({ children, accessLevel }) {
    const user = useContext(UserContext);
   // console.log(user);
    const location = useLocation();

    const isAuthenticated = user.user_email ? true : false;

    const userAutenticated = {
        isAuthenticated: isAuthenticated ,
        role: user.role,
        level:user.role
    }
    
    if (!userAutenticated.isAuthenticated) {
        // Se o usuário não estiver logado, redirecione para a página de login
        return <Navigate to="/login" replace state={{ from: location, errorMessage: 'Você precisa fazer login para acessar esta página.' }} />;
    }

    if(userAutenticated.role === 'administrator'){
        
        if(!accessLevel.includes('administrator') && userAutenticated.level === 'administrator' && userAutenticated.isAuthenticated) {
            // Se o usuário não for administrador, redirecione para a página de login com uma mensagem de erro
            return <Navigate to="/login" replace state={{ from: location, errorMessage: 'Acesso negado. Você não tem permissão para acessar esta página.' }} />;
        }

    }

    if(userAutenticated.role === 'subscriber'){
        
        if(!accessLevel.includes('subscriber') && userAutenticated.level === 'subscriber' && userAutenticated.isAuthenticated) {
            // Se o usuário não for administrador, redirecione para a página de login com uma mensagem de erro
            return <Navigate to="/login" replace state={{ from: location, errorMessage: 'Acesso negado. Você não tem permissão para acessar esta página.' }} />;
        }

    }
   // console.log(accessLevel[0]);

    // Se o usuário tiver o nível de acesso necessário, retorne o componente children
    return children;
}

export default ProtectedRoute;
