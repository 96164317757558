import React, { useState, useEffect } from 'react';
import { Table, Spinner, Alert } from 'reactstrap';
import API from "../../services/axios/Requests";
import { useTranslation } from 'react-i18next';

const CompareTransactions = () => {
  const { t } = useTranslation();
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [matchFound, setMatchFound] = useState(false);

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const response = await API.get('/scrape-transactions');
        const matches = response.data;

        setMatchFound(matches.length > 0);
        setTransactions(matches);
      } catch (error) {
        console.error(t('compareTransactions.fetchError'), error);
      }
      setLoading(false);
    };

    fetchTransactions();
  }, [t]);

  return (
    <div>
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <Spinner type="grow" color="danger" />
        </div>
      ) : matchFound ? (
        <Alert color="success">{t('compareTransactions.transactionsFound')}</Alert>
      ) : (
        <Alert color="danger">{t('compareTransactions.noTransactionsFound')}</Alert>
      )}
      <Table responsive>
        <thead>
          <tr>
            <th>{t('compareTransactions.id')}</th>
            <th>{t('compareTransactions.userId')}</th>
            <th>{t('compareTransactions.value')}</th>
            <th>{t('compareTransactions.transactionType')}</th>
          </tr>
        </thead>
        <tbody>
          {transactions.map((trx, index) => (
            <tr key={index}>
              <td>{trx.id}</td>
              <td>{trx.user_id}</td>
              <td>{trx.value}</td>
              <td>{trx.tipo}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default CompareTransactions;
