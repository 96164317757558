import { useRoutes, BrowserRouter, Switch, Redirect } from "react-router-dom";
import Themeroutes from "./routes/Router";

const App = () => {
  const routing = useRoutes(Themeroutes);

  return <div className="black">{routing}</div>;
};

export default App;
