import React, { useContext } from "react";
import { Button, Nav, NavItem } from "reactstrap";
import Logo from "./Logo";
import { Link, useLocation } from "react-router-dom";
import UserContext from '../hooks/UserContext'; // Importa o contexto do usuário
import { useTranslation } from 'react-i18next';

const Sidebar = () => {
  const { t } = useTranslation();
  const role = useContext(UserContext);
  const location = useLocation();

  const showMobilemenu = () => {
    document.getElementById("sidebarArea").classList.toggle("showSidebar");
  };

  const navigation = [
    {
      title: t("sidebar.dashboard"),
      href: "/starter",
      icon: "bi bi-speedometer2",
      role: ["subscriber"]  // Aparece para ambos os roles
    },
    {
      title: t("sidebar.myWallet"),
      href: "/wallet",
      icon: "bi bi-bell",
      role: ["subscriber"]
    },
    {
      title: t("sidebar.transactionHistory"),
      href: "/transactions",
      icon: "bi bi-patch-check",
      role: ["subscriber"]
    },
    {
      title: t("sidebar.becomeGlobalManager"),
      href: "/gerente",
      icon: "bi bi-hdd-stack",
      role: ["subscriber"]
    },
    {
      title: t("sidebar.referFriend"),
      href: "/indicacao",
      icon: "bi bi-card-text",
      role: ["subscriber"]
    },
    {
      title: t("sidebar.registeredData"),
      href: "/grid",
      icon: "bi bi-columns",
      role: ["subscriber"]
    },
    {
      title: t("sidebar.contactUs"),
      href: "/contato",
      icon: "bi bi-layout-split",
      role: ["subscriber"]
    },
    {
      title: t("sidebar.educational"),
      href: "/educacional",
      icon: "bi bi-textarea-resize",
      role: ["subscriber"]
    },
    {
      title: t("sidebar.dashboard"),
      href: "/dashboardadm",
      icon: "bi bi-speedometer2",
      role: ["administrator"]  // Aparece para ambos os roles
    },
    {
      title: t("sidebar.adminIncome"),
      href: "/income",
      icon: "bi bi-cash-stack",
      role: ["administrator"]
    },
    {
      title: t("sidebar.adminTransactions"),
      href: "/history",
      icon: "bi bi-card-text",
      role: ["administrator"]
    },
    {
      title: t("sidebar.adminUsers"),
      href: "/user",
      icon: "bi bi-people",
      role: ["administrator"]
    },
    {
      title: t("sidebar.adminIncomeChart"),
      href: "/chart",
      icon: "bi bi-bar-chart",
      role: ["administrator"]
    },
    {
      title: t("sidebar.adminNotifications"),
      href: "/notification",
      icon: "bi bi-bell",
      role: ["administrator"]
    },
    {
      title: t("sidebar.adminIndications"),
      href: "/indicacaoadm",
      icon: "bi bi-award",
      role: ["administrator"]
    },
  ];

  // Filtrar os itens de navegação com base no role do usuário
  const filteredNavigation = navigation.filter(nav => nav.role.includes(role?.role));

  return (
    <div className="p-3">
      <div className="d-flex align-items-center">
        <Logo />
        <span className="ms-auto d-lg-none">
          <Button
            close
            size="sm"
            className="ms-auto"
            onClick={() => showMobilemenu()}
          ></Button>
        </span>
      </div>
      <div className="pt-2 mt-2">
        <Nav vertical className="sidebarNav">
          {filteredNavigation.map((navi, index) => (
            <NavItem key={index} className="sidenav-bg">
              <Link
                to={navi.href}
                className={
                  location.pathname === navi.href
                    ? "text-active nav-link py-3"
                    : "nav-link py-3"
                }
                onClick={() => showMobilemenu()}
              >
                <i className={navi.icon}></i>
                <span className="ms-3 d-inline-block">{navi.title}</span>
              </Link>
            </NavItem>
          ))}
        </Nav>
      </div>
    </div>
  );
};

export default Sidebar;
