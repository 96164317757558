import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const FloatingButton = () => {
  const { t } = useTranslation();

  return (
    <Link to="/deposit" className="floating-button">
      <i className='bi bi-arrow-up-right'></i> {t('floatingButton.addFunds')}
    </Link>
  );
};

export default FloatingButton;
