import React from 'react';
import { Container, Row, Col, Card, CardBody, CardTitle, CardText, Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';

const Educacional = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Row className="mt-5">
        <Col sm="12">
          <h2 className="text-center text-white">{t('educacional.title')}</h2>
          <p className="text-center text-white">{t('educacional.subtitle')}</p>
        </Col>
      </Row>

      {/* Seção de Artigos */}
      <Row className="mt-3">
        <Col sm="12">
          <h3 className="text-white">{t('educacional.recentArticles')}</h3>
        </Col>
        {[1, 2, 3].map((id) => (
          <Col md="4" key={id}>
            <Card>
              <CardBody>
                <CardTitle tag="h5">{t('educacional.articleTitle', { id })}</CardTitle>
                <CardText>{t('educacional.articleSummary', { id })}</CardText>
                <Button color="danger">{t('educacional.readMore')}</Button>
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>

      {/* Seção de Vídeos */}
      <Row className="mt-5">
        <Col sm="12">
          <h3 className="text-white">{t('educacional.tutorialVideos')}</h3>
        </Col>
        {[1, 2].map((id) => (
          <Col md="6" key={id}>
            <Card>
              <CardBody>
                <CardTitle tag="h5">{t('educacional.tutorialTitle', { id })}</CardTitle>
                <CardText>{t('educacional.tutorialSummary', { id })}</CardText>
                <Button color="danger">{t('educacional.watchNow')}</Button>
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>

      {/* Dicas de Investimento */}
      <Row className="mt-5 mb-5">
        <Col sm="12">
          <h3 className="text-white">{t('educacional.investmentTips')}</h3>
          <ul className="text-white">
            <li>{t('educacional.tip1')}</li>
            <li>{t('educacional.tip2')}</li>
            <li>{t('educacional.tip3')}</li>
            <li>{t('educacional.tip4')}</li>
          </ul>
        </Col>
      </Row>
    </Container>
  );
};

export default Educacional;
