import { Navigate } from "react-router-dom";

/****Layouts*****/
import FullLayout from "../layouts/FullLayout.js";
import Login from "../views/ui/Login.js";

/***** Pages ****/
import Starter from "../views/Starter.js";
import DashboardAdm from "../views/DashboardAdm.js";
import Gerente from "../views/ui/Gerente";
import Grid from "../views/ui/Grid";
import Contato from "../views/ui/Contato";
import Chart from "../views/ui/Chart";

/****  PAGES ALPHABULL *****/
import Wallet from "../views/ui/Wallet";
import Deposit from "../views/ui/Deposit";
import Withdraw from "../views/ui/Withdraw";
import GetInvest from "../views/ui/GetInvest";
import Transfer from "../views/ui/Transfer";
import Transactions from "../views/ui/Transactions";
import History from "../views/ui/History";
import User from "../views/ui/User";
import Income from "../views/ui/Income";
import Notification from "../views/ui/Notification";
import CompareTransactions from "../views/ui/CompareTransactions";
import IndicacaoAdm from "../views/ui/IndicacaoAdm";
import Indicacao from "../views/ui/Indicacao";
import Educacional from "../views/ui/Educacional";
import Invest from "../views/ui/Invest";
import ProtectedRoute from "../routes/ProtectedRouter";
/*****Routes******/
const ThemeRoutes = [
  { path: "/login", element: <Login /> },
  {
    path: "/",
    element: <FullLayout />,
    children: [

      // pages subscriber
      { path: "/", element: <Navigate to="/Login" replace={true} /> }, 
      { path: "/gerente", element: <ProtectedRoute accessLevel={['subscriber']}><Gerente /></ProtectedRoute> }, 
      { path: "/grid", element: <ProtectedRoute accessLevel={['subscriber']}><Grid /></ProtectedRoute> },
      { path: "/wallet", element: <ProtectedRoute accessLevel={['subscriber']}><Wallet /></ProtectedRoute> },
      { path: "/deposit", element: <ProtectedRoute accessLevel={['subscriber']}><Deposit /></ProtectedRoute> },
      { path: "/withdraw", element: <ProtectedRoute accessLevel={['subscriber']}><Withdraw /></ProtectedRoute> },
      { path: "/transactions", element: <ProtectedRoute accessLevel={['subscriber']}><Transactions /></ProtectedRoute> },
      { path: "/indicacao", element: <ProtectedRoute accessLevel={['subscriber']}><Indicacao /></ProtectedRoute> },
      { path: "/contato", element: <ProtectedRoute accessLevel={['subscriber']}><Contato /></ProtectedRoute> },
      { path: "/educacional", element: <ProtectedRoute accessLevel={['subscriber']}><Educacional /></ProtectedRoute> },
      { path: "/invest", element: <ProtectedRoute accessLevel={['subscriber']}><Invest /></ProtectedRoute> },
      { path: "/transfer", element: <ProtectedRoute accessLevel={['subscriber']}><Transfer /></ProtectedRoute> },
      { path: "/getinvest", element: <ProtectedRoute accessLevel={['subscriber']}><GetInvest /></ProtectedRoute> },
      
      
      // pages user and administrator
      { path: "/starter", element: <ProtectedRoute accessLevel={['subscriber','administrator']}><Starter /></ProtectedRoute>},
      { path: "/chart", element: <ProtectedRoute accessLevel={['subscriber','administrator']}><Chart /></ProtectedRoute>},

      // pages admin      
      { path: "/history", element: <ProtectedRoute accessLevel={['administrator']}><History /></ProtectedRoute>  },
      { path: "/income", element: <ProtectedRoute accessLevel={['administrator']}><Income /></ProtectedRoute>  },
      { path: "/notification", element: <ProtectedRoute accessLevel={['administrator']}><Notification /></ProtectedRoute>  },
      { path: "/compareTransactions", element: <ProtectedRoute accessLevel={['administrator']}><CompareTransactions /></ProtectedRoute>  },
      { path: "/indicacaoadm", element: <ProtectedRoute accessLevel={['administrator']}><IndicacaoAdm /></ProtectedRoute>  },
      { path: "/user", element: <ProtectedRoute accessLevel={['administrator']}><User /></ProtectedRoute>  },
      { path: "/dashboardadm", element: <ProtectedRoute accessLevel={['administrator']}><DashboardAdm /></ProtectedRoute>  },


    ],
  },
];







export default ThemeRoutes;
