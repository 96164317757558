import React, { useState, useEffect, useContext } from "react";
import API from "../../services/axios/Requests";
import { NumericFormat } from 'react-number-format';
import { UserContext } from '../../hooks/UserContext'; // Importa o contexto do usuário
import {
  Card,
  Alert,
  Row,
  Col,
  CardTitle,
  CardBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  Spinner,
  InputGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import { useTranslation } from 'react-i18next';

const Transfer = () => {
  const { t } = useTranslation();
  const [walletDest, setWalletDest] = useState(""); 
  const [walletOrigin, setWalletOrigin] = useState(""); // Adiciona estado para o valor do input
  const [walletValue, setWalletValue] = useState(null); 
  const [quantity, setQuantity] = useState(""); 
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const user = useContext(UserContext); // Obtém o contexto do usuário

  const Loading = () => (
    <Spinner size="lg"> {t('transfer.loading')}</Spinner>
  );

  const MoneyIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-currency-dollar" viewBox="0 0 16 16">
      <path d="M4 10.781c.148 1.667 1.513 2.85 3.591 3.003V15h1.043v-1.216c2.27-.179 3.678-1.438 3.678-3.3 0-1.59-.947-2.51-2.956-3.028l-.722-.187V3.467c1.122.11 1.879.714 2.07 1.616h1.47c-.166-1.6-1.54-2.748-3.54-2.875V1H7.591v1.233c-1.939.23-3.27 1.472-3.27 3.156 0 1.454.966 2.483 2.661 2.917l.61.162v4.031c-1.149-.17-1.94-.8-2.131-1.718zm3.391-3.836c-1.043-.263-1.6-.825-1.6-1.616 0-.944.704-1.641 1.8-1.828v3.495l-.2-.05zm1.591 1.872c1.287.323 1.852.859 1.852 1.769 0 1.097-.826 1.828-2.2 1.939V8.73z"/>
    </svg>
  );

  const CopyIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-copy" viewBox="0 0 16 16">
      <path fillRule="evenodd" d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"/>
    </svg>
  );

  // Função para lidar com o envio do formulário
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (parseFloat(quantity) > parseFloat(walletValue)) {
      setErrorMessage([t('transfer.exceedBalanceError')]);
      setLoading(false);
      return; // Interrompe o envio do formulário
    }

    const dataToSend = new FormData();
    dataToSend.append('wallet_origin', user.id);
    dataToSend.append('wallet_dest', walletDest);
    dataToSend.append('quantity', quantity);
    dataToSend.append('type_of', 'transfer_fundos');
    dataToSend.append('user_id', user.id);

    try {
      await API.post('/transaction', dataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      setLoading(false);
      setModal(true); // Abre o modal de confirmação
      setWalletOrigin("");
      setWalletDest("");
      setQuantity("");
      setErrorMessage("");

    } catch (error) {
      setLoading(false);
      if (error.response && error.response.data && error.response.data.errors) {
        // Extrai todas as mensagens de erro específicas para um array
        const errors = error.response.data.errors;
        const errorMessages = Object.keys(errors)
            .flatMap(field => errors[field]); // Usa flatMap para criar um array plano de mensagens
        setErrorMessage(errorMessages);
      } else {
        // Uma mensagem de erro genérica caso a estrutura do erro seja desconhecida
        setErrorMessage([t('transfer.genericError')]);
      }
    }
  };

  const fetchWalletValue = async () => {
    try {
      const response = await API.get(`/wallet/${user.id}`);
      setWalletValue(response.data.wallet.income);
    } catch (error) {
      console.error(t('transfer.fetchWalletError'), error);
    }
  };

  useEffect(() => {
    fetchWalletValue(); // Chamando a função para buscar o valor da carteira ao montar o componente
  }, [t, user.id]);

  // Função para alternar a visibilidade do modal
  const toggleModal = () => setModal(!modal);

  return (
    <Row>
      <Col>
        {/* --------------------------------------------------------------------------------*/}
        {/* Card-1*/}
        {/* --------------------------------------------------------------------------------*/}
        <Card>
          <CardTitle tag="h2" className="border-bottom p-3 mb-0">
            <i className='bi bi-arrow-left-right' style={{fontSize: '2rem', marginRight: '1rem'}}></i>{t('transfer.title')}
          </CardTitle>
          <CardBody>
            {errorMessage.length > 0 && (
              <Alert color="danger">
                <ul>
                  {errorMessage.map((msg, index) => (
                    <li key={index}><b>{msg}</b> {t('transfer.required')}</li>
                  ))}
                </ul>
              </Alert>
            )}
            <Form onSubmit={handleSubmit}>
              <FormGroup className="mb-4">
                <Label for="quantityInput"><b>{t('transfer.amount')}</b></Label>
                <InputGroup>
                  <NumericFormat
                    id="quantity"
                    name="quantity"
                    placeholder={t('transfer.amountPlaceholder')}
                    className="form-control"
                    value={quantity}
                    onValueChange={(values) => {
                      const { value } = values;
                      // Atualiza o estado com o valor não formatado
                      setQuantity(value);
                    }}
                    thousandSeparator="."
                    decimalSeparator=","
                    allowNegative={false}
                    decimalScale={2} // Permite até dois dígitos após o ponto decimal
                    fixedDecimalScale={true} // Aplica sempre duas casas decimais
                    isNumericString={true}
                  />
                  <Button>
                    <MoneyIcon />
                  </Button>
                </InputGroup>
                <FormText>
                  {t('transfer.totalBalance')}: <strong>{walletValue !== null ? `${Number(walletValue).toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 })} USDT` : '...'}</strong>
                </FormText>
              </FormGroup>
              <FormGroup>
                <InputGroup>
                  <Input 
                    id="wallet_origin"
                    name="wallet_origin"
                    placeholder="Ex: Weddrfr56Yxyrdcce5yvdwaded"
                    type="hidden"
                    bsSize="lg"
                    value={user.id}
                    onChange={(e) => setWalletOrigin(e.target.value)}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <Label for="wallet_dest"><b>{t('transfer.destUser')}</b></Label>
                <InputGroup>
                  <Input 
                    id="wallet_dest"
                    name="wallet_dest"
                    placeholder={t('transfer.destUserPlaceholder')}
                    type="text"
                    bsSize="lg"
                    value={walletDest}
                    onChange={(e) => setWalletDest(e.target.value)}
                  />
                </InputGroup>
              </FormGroup>
              <Button type="submit" className="mt-2" color="success" size="lg">
                {loading ? <Spinner size="sm" /> : null} {t('transfer.confirm')}
              </Button>
            </Form>
          </CardBody>
        </Card>
      </Col>
      {/* Modal de confirmação */}
      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>{t('transfer.confirmation')}</ModalHeader>
        <ModalBody>
          {t('transfer.successMessage')}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => { toggleModal(); window.location.href = "/transactions"; }}>{t('transfer.close')}</Button>
        </ModalFooter>
      </Modal>
    </Row>
  );
};

export default Transfer;
