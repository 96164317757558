import React from 'react';

const storedUserJSON = localStorage.getItem('user');
let storedUser = null;

try {
    storedUser = storedUserJSON ? JSON.parse(storedUserJSON) : null;
} catch (error) {
    console.error('Erro ao analisar JSON do usuário:', error);
}

const storedRole = localStorage.getItem('role');
const storedId = localStorage.getItem('id');
const storedCountry = localStorage.getItem('country');

console.log(storedUser);

export const UserContext = React.createContext({
    isAuthenticated: false,
    role: storedRole,
    level: storedRole,
    id: storedId,
    country: storedCountry,
    user_nicename: storedUser?.user_nicename,
    user_email: storedUser?.user_email,
    user_login: storedUser?.user_login,
    user_pass: storedUser?.user_pass,
    user_status: storedUser?.user_status,
    user_registred: storedUser?.user_registred,
});

export default UserContext;
