import React, { useState, useEffect, useContext } from "react";
import Topo from "../components/dashboard/Topo";
import Logo from "../layouts/Logo";
import { Link } from "react-router-dom";
import API from "../services/axios/Requests";
import { useTranslation } from 'react-i18next';

import {
  Navbar,
  Collapse,
  Nav,
  NavItem,
  NavbarBrand,
  DropdownToggle,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Button,
} from "reactstrap";

import { UserContext } from '../hooks/UserContext'; // Importa o contexto do usuário

// Estilo CSS para a bolinha vermelha de notificação
const styles = `
  .notification-dot {
    position: absolute;
    top: 0;
    right: 0;
    width: 10px;
    height: 10px;
    background-color: red;
    border-radius: 50%;
  }
`;

const Header = () => {

  const [isOpen, setIsOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownOpenMobile, setDropdownOpenMobile] = useState(false);
  const [notificationDropdownOpen, setNotificationDropdownOpen] = useState(false);
  const [notificationDropdownOpenMobile, setNotificationDropdownOpenMobile] = useState(false);
  const [languageDropdownOpen, setLanguageDropdownOpen] = useState(false);
  const [languageDropdownOpenMobile, setLanguageDropdownOpenMobile] = useState(false);
  const [walletValue, setWalletValue] = useState(null);
  const [notificationSeen, setNotificationSeen] = useState(false);

  const user = useContext(UserContext); // Obtém o contexto do usuário
  const [notifications, setNotifications] = useState([]);

  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const toggleLanguage = () => setLanguageDropdownOpen(prevState => !prevState);
  const toggleLanguageMobile = () => setLanguageDropdownOpenMobile(prevState => !prevState);
  const toggle = () => setDropdownOpen(prevState => !prevState);
  const toggleMobile = () => setDropdownOpenMobile(prevState => !prevState);
  
  const toggleNotifications = async () => {
    try {
      // Enviar uma solicitação para marcar as notificações como vistas no backend
      await API.post(`/notification/mark-as-seen/${user.id}`);
      // Atualizar o estado local para refletir que as notificações foram vistas
      setNotificationSeen(false);
    } catch (error) {
      console.error("Erro ao marcar notificações como vistas:", error);
    }
    setNotificationDropdownOpen(prevState => !prevState);
  };

  const toggleNotificationsMobile = async () => {
    try {
      // Enviar uma solicitação para marcar as notificações como vistas no backend
      await API.post(`/notification/mark-as-seen/${user.id}`);
      // Atualizar o estado local para refletir que as notificações foram vistas
      setNotificationSeen(false);
    } catch (error) {
      console.error("Erro ao marcar notificações como vistas:", error);
    }
    setNotificationDropdownOpenMobile(prevState => !prevState);
  };

  
  const showMobileMenu = () => {
    document.getElementById("sidebarArea").classList.toggle("showSidebar");
  };

  // Função para buscar o valor da carteira do usuário
  const fetchWalletValue = async () => {
    if (user && user.id) { // Verifique se user e user_id estão definidos
      try {
        const response = await API.get(`/wallet/${user.id}`);
        setWalletValue(response.data.wallet.quantity);
      } catch (error) {
        console.error("Erro ao buscar o valor da carteira: ", error);
      }
    } else {
      console.error("User ID não disponível");
    }
  };

  const handLogount = () => {
    localStorage.removeItem('user');
    window.location.href = '/login';
  };

  const MinhaConta = () => {
    window.location.href = '/grid';
  };

  const MinhaCarteira = () => {
    window.location.href = '/wallet';
  };

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await API.get(`/notification/header/${user.id}`);
        console.log("Notificações: ", response.data);

        if (response.data.length === 0 || response.data.every(notification => notification.seen === 1)) {
          // Se não houver notificações ou todas forem vistas, definir uma notificação padrão
          setNotifications([{ description: t('header.noNewNotifications') }]);
          setNotificationSeen(false); // Nenhuma notificação não vista
        } else {
          // Caso contrário, definir as notificações recebidas
          setNotifications(response.data);

          // Verificar se há alguma notificação não vista
          const hasUnseenNotification = response.data.some(notification => notification.seen === 0);
          setNotificationSeen(hasUnseenNotification);
        }
      } catch (error) {
        console.error("Erro ao buscar notificações:", error);
      }
    };

    fetchNotifications();
    fetchWalletValue();
  }, [user.id, t]);


  return (
    <>
      <Navbar color="dark" dark expand="sm" className="navbar-custom">
        <div className="d-flex align-items-center">
          <NavbarBrand href="/" className="d-lg-none">
            <Logo className="logo" />
          </NavbarBrand>
        </div>

        <div className="hstack gap-2 ">
          {/* Ícones visíveis apenas em mobile (exemplo: sino de notificações e perfil) */}
          <div className="d-sm-none d-flex gap-2">
            <div className="circle-box md-box d-inline-block text-white" style={{ marginTop: '-7px' , marginLeft: '-18px' , marginRight:'25px' }}>
                <Dropdown isOpen={notificationDropdownOpenMobile} toggle={toggleNotificationsMobile}>
                <DropdownToggle color="dark">
                  <div className={`circle-box md-box d-inline-block text-white`}>
                    <i className='bi bi-bell'></i>
                    {notificationSeen && <div className="notification-dot"></div>}
                  </div>
                </DropdownToggle>
                {notificationDropdownOpenMobile && (
                <DropdownMenu>
                  {notifications.map((notification, index) => (
                    <DropdownItem key={index}>
                      <Button
                        className="rounded-circle me-3 mb-2"
                        size="sm"
                        color="danger" // Defina a cor padrão
                      >
                        <i className="bi bi-bell"></i>
                      </Button>
                      {notification.description}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
                )}
              </Dropdown>
            </div>
            <div className="circle-box md-box d-inline-block text-white gap-2" style={{ marginTop: '-7px' , marginLeft: '-8px' , marginRight:'13px' }}>
                <Dropdown isOpen={dropdownOpenMobile} toggle={toggleMobile}>
                <DropdownToggle color="dark">
                  <div className={`circle-box md-box d-inline-block text-white`}>
                    <i className='bi bi-person'></i>
                  </div>
                </DropdownToggle>
                {dropdownOpenMobile && (
                <DropdownMenu>
                {user.role !== 'administrator' && (
                  <>
                    <DropdownItem onClick={MinhaConta}>{t('header.myAccount')}</DropdownItem>
                    <DropdownItem onClick={MinhaCarteira}>{t('header.myWallet')}</DropdownItem>
                    <DropdownItem divider />
                    {/* <DropdownItem>Notificações</DropdownItem> */}
                  </>
                )}
                <DropdownItem onClick={handLogount}>{t('header.logout')}</DropdownItem>
              </DropdownMenu>
                )}
              </Dropdown>
            </div>
            <div className="circle-box md-box d-inline-block text-white " style={{ marginTop: '-7px', marginLeft:'5px'  }}>
                <Dropdown isOpen={languageDropdownOpenMobile} toggle={toggleLanguageMobile}>
                  <DropdownToggle color="dark">
                    <div className={`circle-box md-box text-white`}>
                      <i className='bi bi-globe2'></i>
                    </div>
                  </DropdownToggle>
                  {languageDropdownOpenMobile && (
                    <DropdownMenu>
                      <DropdownItem onClick={() => changeLanguage('pt')}>{t('header.portuguese')}</DropdownItem>
                      <DropdownItem onClick={() => changeLanguage('en')}>{t('header.english')}</DropdownItem>
                      <DropdownItem onClick={() => changeLanguage('es')}>{t('header.spanish')}</DropdownItem>
                    </DropdownMenu>
                  )}
                </Dropdown>
            </div>
          </div>
        </div>

        <Collapse navbar isOpen={isOpen}>
          <Nav className="me-auto ml-5" navbar>
            <NavItem style={{ display: user.role === 'administrator' ? 'none' : 'block' }} className="text-alpha">{t('header.clientArea')}: <span className="text-white">{user?.user_nicename} </span> </NavItem>
            <NavItem style={{ display: user.role === 'subscriber' ? 'none' : 'block' }} className="text-alpha">{t('header.adminArea')}: <span className="text-white">{user?.user_nicename} </span> </NavItem>
          </Nav>
          {/* Dropdowns serão incluídos no Collapse para visibilidade em telas maiores */}
          <Dropdown isOpen={languageDropdownOpen} toggle={toggleLanguage} className="d-none d-sm-block" >
            <DropdownToggle color="dark">
              <div className={`circle-box md-box d-inline-block text-white`}>
                <i className='bi bi-globe2'></i>
              </div>
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={() => changeLanguage('pt')}>{t('header.portuguese')}</DropdownItem>
              <DropdownItem onClick={() => changeLanguage('en')}>{t('header.english')}</DropdownItem>
              <DropdownItem onClick={() => changeLanguage('es')}>{t('header.spanish')}</DropdownItem>
            </DropdownMenu>
          </Dropdown>
          <Dropdown isOpen={notificationDropdownOpen} toggle={toggleNotifications}>
            <DropdownToggle color="dark">
              <div className={`circle-box md-box d-inline-block text-white`}>
                <i className='bi bi-bell'></i>
                {notificationSeen && <div className="notification-dot"></div>}
              </div>
            </DropdownToggle>
            <DropdownMenu className="dropdown-notification dropdown-menu-end">
              {notifications.map((notification, index) => (
                <DropdownItem key={index} className="dropdown-item-notification">
                  <Button
                    className="rounded-circle me-3 mb-2"
                    size="sm"
                    color="danger" // Defina a cor padrão
                  >
                    <i className="bi bi-bell"></i>
                  </Button>
                  {notification.description}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>
          <Dropdown isOpen={dropdownOpen} toggle={toggle} className="d-none d-sm-block">
            <DropdownToggle color="dark">
              <div className={`circle-box md-box d-inline-block text-white`}>
                <i className='bi bi-person'></i>
              </div>
            </DropdownToggle>
            <DropdownMenu className="dropdown-user">
              {user.role !== 'administrator' && (
                <>
                  <DropdownItem onClick={MinhaConta}>{t('header.myAccount')}</DropdownItem>
                  <DropdownItem onClick={MinhaCarteira}>{t('header.myWallet')}</DropdownItem>
                  <DropdownItem divider />
                  {/* <DropdownItem>Notificações</DropdownItem> */}
                </>
              )}
              <DropdownItem onClick={handLogount}>{t('header.logout')}</DropdownItem>
            </DropdownMenu>
          </Dropdown>

        </Collapse>
        <div className="topo">
          <Nav className="area-segura" navbar style={{ flexGrow: 1 }}>
            <NavItem 
              style={{ display: user.role === 'administrator' ? 'none' : 'block' }} 
              className="text-alpha"
            >
              {t('header.clientArea')}: <span className="text-white">{user?.user_nicename}</span>
            </NavItem>
            <NavItem 
              style={{ display: user.role === 'administrator' ? 'block' : 'none' }} 
              className="text-alpha"
            >
              {t('header.adminArea')}: <span className="text-white">{user?.user_nicename}</span>
            </NavItem>
          </Nav>
          <Button
            color="dark"
            className="d-lg-none"
            onClick={showMobileMenu}
            style={{ marginLeft: 'auto' }}
          >
            <i className="bi bi-list" style={{ height: '90px', width: '90px' }}></i>
          </Button>
        </div>
      </Navbar>

      <Topo
        title={t('header.dashboardTitle')}
        subtitle={t('header.investmentSubtitle')}
        icon="bi bi-currency-dollar"
        earning={walletValue !== null ? `${Number(walletValue).toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 })} USDT` : '...'}
        bg="text-white"
      />
    </>
  );
};

export default Header;
