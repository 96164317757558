import logoAlpha from "../assets/images/logos/logo_alpha.png"; // Importa a imagem PNG
import { Link } from "react-router-dom";

const Logo = () => {
  return (
    <Link to="/">
      {/* Utiliza a tag img para a imagem PNG com estilos aplicados */}
      <img src={logoAlpha} alt="Logo" className="logo" />
    </Link>
  );
};

export default Logo;

