import { Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";

const TopCards = (props) => {
  return (
    <Link to={props.to} style={{ textDecoration: 'none' }}> 
    <Card className="cards-topo">
      <CardBody>
        <div className="d-flex align-items-center">
          <div className={`circle-box md-box d-inline-block ${props.bg}`}>
            <i className={props.icon}></i>
          </div>
          <div className="ms-3">
            <h5 className="text-card-topo">{props.earning}</h5>
          </div>
        </div>
      </CardBody>
    </Card>
    </Link>
  );
};

export default TopCards;
