import { Col, Row, Card, CardBody, CardTitle, Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import React, { useState, useEffect, useContext } from "react";
import SalesChart from "../components/dashboard/SalesChart";
import Feeds from "../components/dashboard/Feeds";
import ProjectTables from "../components/dashboard/ProjectTable";
import Tabela from "../components/dashboard/Tabela";
import TopCards from "../components/dashboard/TopCards";
import CardsDetail from "../components/dashboard/CardsDetail";
import API from "../services/axios/Requests";
import { UserContext } from '../hooks/UserContext'; // Importa o contexto do usuário
import { useTranslation } from 'react-i18next';

const DashboardAdm = () => {
  const { t } = useTranslation();

  const user = useContext(UserContext); // Obtém o contexto do usuário
  const [userInativeValue, setuserInativeValue] = useState(null); 
  const [transactionInativeValue, settransactionInativeValue] = useState(null); 
  const [userAtiveValue, setuserAtiveValue] = useState(null); 
  const [showAlert, setShowAlert] = useState(false);
  const [chartData, setChartData] = useState([]);

  const handleSave = async (data) => {
    setChartData([
      { name: "Commodities", data: data.commoditiesData },
      { name: "Forex", data: data.forexData },
      { name: "Crypto", data: data.cryptoData }
    ]);

    // Aqui você pode fazer uma chamada de API para salvar os dados no banco
    try {
      await API.post("/chart-data", data);
      console.log("Dados salvos com sucesso!");
    } catch (error) {
      console.error("Erro ao salvar os dados:", error);
    }
  };

  // Função para buscar o valor da carteira do usuário
  const fetchuserInativeValue = async () => {
    if (user && user.id) {
      try {
        const response = await API.get(`/users/inative`);
        console.log("Valor da carteira do usuário: ", response.data);
        setuserInativeValue(response.data);

        const response2 = await API.get(`/transactions/inative`);
        settransactionInativeValue(response2.data);

        const response3 = await API.get(`/users/ative`);
        setuserAtiveValue(response3.data);
        
      } catch (error) {
        console.error("Erro ao buscar o valor da carteira: ", error);
      }
    } else {
      console.error("User ID não disponível");
    }
  };

  useEffect(() => {
    fetchuserInativeValue();
  }, []);

  return (
    <div>
      {/* Modal de alerta */}
      <Modal isOpen={showAlert} toggle={() => setShowAlert(!showAlert)}>
        <ModalHeader toggle={() => setShowAlert(!showAlert)}>{t('dashboardAdm.alertTitle')}</ModalHeader>
        <ModalBody>
          <div>
            <p style={{ textAlign: "center" }}>
              <b>{t('dashboardAdm.noInvestmentBalance')}</b> <br />
              {t('dashboardAdm.addFundsToInvest')}
            </p>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={() => window.location.href = "/deposit"}>{t('dashboardAdm.addFundsButton')}</Button>
        </ModalFooter>
      </Modal>

      {/***Top Cards***/}
      <Row>
        <Col sm="4" lg="4">
          <CardsDetail
            bg={userInativeValue}
            title=""
            subtitle={t('dashboardAdm.yearlyEarning')}
            earning={<span>{t('dashboardAdm.pendingUsers')}</span>}
            to="/user"
          />
        </Col>
        <Col sm="4" lg="4">
          <CardsDetail
            bg={transactionInativeValue}
            title=""
            subtitle={t('dashboardAdm.pendingTransactions')}
            earning={<span>{t('dashboardAdm.pendingTransactions')}</span>}
            icon="bi bi-arrow-up-right"
            to="/history"
          />
        </Col>
        <Col sm="4" lg="4">
          <CardsDetail
            bg={userAtiveValue}
            title=""
            subtitle={t('dashboardAdm.refundGiven')}
            earning={<span>{t('dashboardAdm.activeUsers')}</span>}
            icon="bi bi-arrow-up-right"
            to="/user"
          />
        </Col>
      </Row>

      {/***Sales & Feed***/}
      <Row>
        <Col lg="12" xxl="12" md="12">
          <SalesChart chartData={chartData} />
        </Col>
      </Row>
    </div>
  );
};

export default DashboardAdm;
