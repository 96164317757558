import React, { useEffect, useState, useContext } from "react";
import { Card, CardBody, Table, Spinner } from "reactstrap";
import API from "../../services/axios/Requests";
import { UserContext } from '../../hooks/UserContext';

const TabelaIndication = () => {
  const [indications, setIndications] = useState([]);
  const [loading, setLoading] = useState(true);
  const user = useContext(UserContext);

  useEffect(() => {
    const fetchIndications = async () => {
      setLoading(true); // Garantir que o estado de carregamento seja verdadeiro ao iniciar o fetch
      if (user && user.id) {
        try {
          const response = await API.get(`/users/indication/${user.id}`);
          setIndications(response.data);
        } catch (error) {
          console.error("Erro ao buscar indicações: ", error);
        }
      } else {
        console.error("Não há indicações disponíveis para o usuário.");
      }
      setLoading(false); // Desativar o estado de carregamento após o fetch
    };

    fetchIndications();
  }, [user?.id]); // Adiciona user.id como dependência para re-fetch quando o ID do usuário mudar

  return (
    <div>
      <Card>
        <CardBody>
          {loading ? (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
              <Spinner type="grow" color="danger" />
            </div>
          ) : indications.length > 0 ? (
            <Table className="no-wrap mt-3 align-middle" responsive borderless>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Usuários</th>
                  <th>Data</th>
                </tr>
              </thead>
              <tbody>
                {indications.map((indication, index) => (
                  <tr key={index} className="border-top">
                    <td className="pt-3 pb-3">{index + 1}</td>
                    <td className="pt-3 pb-3">{indication.referred_nickname}</td>
                    <td className="pt-3 pb-3">
                      {new Date(indication.indication_date).toLocaleDateString('pt-BR')}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <div style={{ textAlign: 'center', marginTop: 20 }}>Nenhuma indicação encontrada.</div>
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default TabelaIndication;
