import { Card, CardBody, CardTitle } from "reactstrap";
import Tabela from "../../components/dashboard/Tabela";
import { useTranslation } from 'react-i18next';

export const History = () => {
  const { t } = useTranslation();

  return (
    <Card>
      <CardTitle tag="h2" className="border-bottom p-3 mb-0">
        <i className='bi bi-card-list' style={{fontSize: '2rem', marginRight: '1rem'}}></i>{t('history.transactionHistory')}
      </CardTitle>
      <CardBody>
        <Tabela />
        {/* <Feeds /> */}
      </CardBody>
    </Card>
  );
};

export default History;
