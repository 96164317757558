import React, { useState } from "react";
import { Card, CardBody } from "reactstrap";
import SalesChart from "../../components/dashboard/SalesChart";
import ChartForm from "../../components/dashboard/ChartForm";
import API from "../../services/axios/Requests"; // Assumindo que você já tem isso configurado
import { useTranslation } from 'react-i18next';

const Chart = () => {
  const { t } = useTranslation();
  const [chartData, setChartData] = useState([]);

  const handleSave = async (data) => {
    setChartData([
      { name: "Commodities", data: data.commoditiesData },
      { name: "Forex", data: data.forexData },
      { name: "Crypto", data: data.cryptoData }
    ]);

    // Aqui você pode fazer uma chamada de API para salvar os dados no banco
    try {
      await API.post("/chart-data", data);
      console.log(t('chart.dataSavedSuccess'));
    } catch (error) {
      console.error(t('chart.dataSaveError'), error);
    }
  };

  return (
    <div>
      <Card>
        <CardBody>
          <ChartForm onSave={handleSave} />
        </CardBody>
      </Card>
      <SalesChart chartData={chartData} />
    </div>
  );
};

export default Chart;
