import React, { useEffect, useState } from "react";
import { Card, CardBody, Table, Spinner, Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import API from "../../services/axios/Requests"; // Assumindo que você já tem isso configurado
import { useTranslation } from 'react-i18next';

const Tabela = () => {
  const { t } = useTranslation();
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [confirmModal, setConfirmModal] = useState({ isOpen: false, transactionId: null, action: null, quantity: null, userId: null, wallet_dest: null });

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const response = await API.get('/transactions/all');
        setTransactions(response.data);
        setLoading(false);
      } catch (error) {
        console.error(t("table.fetchError"), error);
        setLoading(false);
      }
    };

    fetchTransactions();
  }, [t]);

  const openConfirmModal = (id, action, quantity = null, userId = null, type_of = null, wallet_dest = null) => {
    setConfirmModal({ isOpen: true, transactionId: id, action: action, quantity: quantity, userId: userId, type_of: type_of, wallet_dest: wallet_dest });
  };

  const closeConfirmModal = () => {
    setConfirmModal({ ...confirmModal, isOpen: false });
    window.location.reload();
  };

  const confirmAction = async () => {
    if (confirmModal.action === "approve") {
      await approveTransaction(confirmModal.transactionId);
    } else if (confirmModal.action === "reject") {
      if (confirmModal.transactionId === 1) {
        const response = await API.post(`/transactions/${confirmModal.transactionId}`, { quantity: confirmModal.quantity, userId: confirmModal.userId, wallet_dest: confirmModal.wallet_dest });
        console.log(response);
      } else {
        await rejectTransaction(confirmModal.transactionId);
      }
    }
    closeConfirmModal();
  };

  const approveTransaction = async (id) => {
    try {
      await API.post(`/transactions/approve/${id}`, { status: 1, quantity: confirmModal.quantity, userId: confirmModal.userId, type_of: confirmModal.type_of, wallet_dest: confirmModal.wallet_dest });
      // Atualiza o estado das transações após aprová-la
      const updatedTransactions = transactions.map(transaction => {
        if (transaction.id === id) {
          transaction.status = 1;
        }
        return transaction;
      });
      setTransactions(updatedTransactions);
    } catch (error) {
      console.error(t("table.approveError"), error);
    }
  };

  const rejectTransaction = async (id) => {
    try {
      await API.post(`/transactions/approve/${id}`, { status: 0, quantity: confirmModal.quantity, userId: confirmModal.userId, type_of: confirmModal.type_of, wallet_dest: confirmModal.wallet_dest });
    } catch (error) {
      console.error(t("table.rejectError"), error);
    }
  };

  return (
    <div>
      <Card>
        <CardBody>
          {loading ? (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
              <Spinner type="grow" color="danger" />
            </div>
          ) : (
            <Table className="no-wrap mt-3 align-middle" responsive borderless>
              <thead>
                <tr>
                  <th>{t("table.user")}</th>
                  <th>{t("table.transactionType")}</th>
                  <th>{t("table.originWallet")}</th>
                  <th>{t("table.destinationWallet")}</th>
                  <th>{t("table.status")}</th>
                  <th>{t("table.value")}</th>
                  <th>{t("table.date")}</th>
                  <th>{t("table.actions")}</th>
                </tr>
              </thead>
              <tbody>
                {transactions.map((transaction, index) => (
                  <tr key={index} className="border-top">
                    <td className="pt-3 pb-3">
                      <div className="d-flex align-items-center">
                        <strong>{transaction?.user_name}</strong>
                      </div>
                    </td>
                    <td className="pt-3 pb-3">
                      <div className="d-flex align-items-center">
                        <i className={`${transaction?.icon} me-3`}></i>
                        <strong>
                          {transaction?.status === 1 && transaction?.name === t("table.requestWithdraw") ? (
                            t("table.withdrawCompleted")
                          ) : transaction?.status === 0 && transaction?.name === t("table.requestWithdraw") ? (
                            t("table.requestWithdraw")
                          ) : transaction?.status === 1 && transaction?.name === t("table.requestInvestmentWithdraw") ? (
                            t("table.investmentWithdrawCompleted")
                          ) : transaction?.status === 0 && transaction?.name === t("table.requestInvestmentWithdraw") ? (
                            t("table.requestInvestmentWithdraw")
                          ) : (
                            transaction?.name
                          )}
                        </strong>
                      </div>
                    </td>
                    <td className="pt-3 pb-3">
                      <div className="d-flex align-items-center">
                        <strong>
                          {/^\d+$/.test(transaction?.wallet_origin) ? transaction?.user_name : transaction?.wallet_origin}
                        </strong>
                      </div>
                    </td>
                    <td className="pt-3 pb-3">{transaction?.wallet_dest}</td>
                    <td className="pt-3 pb-3">
                      {transaction?.status === 1 ? (
                        <i className="bi bi-check-circle-fill text-success"></i>
                      ) : transaction?.status === 0 ? (
                        <i className="bi bi-hourglass-split text-danger"></i>
                      ) : (
                        <i className="bi bi-exclamation-circle-fill text-warning"></i>
                      )}
                    </td>
                    <td className="pt-3 pb-3">
                      {`${transaction?.quantity.toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 })} USDT`}
                    </td>
                    <td className="pt-3 pb-3">
                      {new Date(transaction?.created_at).toLocaleDateString('pt-BR')}
                    </td>
                    <td className="pt-3 pb-3">
                      <Button title={t("table.approve")} color="success" disabled={transaction?.status === 1} onClick={() => openConfirmModal(transaction?.id, "approve", transaction?.id === 1 ? transaction?.quantity : transaction?.quantity, transaction?.user_id, transaction?.type_of, transaction?.wallet_dest)}><i className="bi bi-check-circle-fill"></i></Button>{"   "}
                      <Button title={t("table.reject")} color="danger" disabled={transaction?.status === 1} onClick={() => openConfirmModal(transaction?.id, "reject", transaction?.id === 1 ? transaction?.quantity : transaction?.quantity, transaction?.user_id, transaction?.type_of, transaction?.wallet_dest)}><i className="bi bi-x-circle-fill"></i></Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </CardBody>
      </Card>
      {/* Modal de confirmação */}
      <Modal isOpen={confirmModal.isOpen} toggle={closeConfirmModal}>
        <ModalHeader toggle={closeConfirmModal}>{t("table.confirmation")}</ModalHeader>
        <ModalBody>
          {t("table.confirmAction")}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={confirmAction}>{t("table.confirm")}</Button>
          <Button color="secondary" onClick={closeConfirmModal}>{t("table.cancel")}</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default Tabela;
