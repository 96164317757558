import { Card, CardBody } from "reactstrap";
import React, { useState, useEffect, useContext  } from "react";
import { Link } from "react-router-dom";
import { UserContext} from '../../hooks/UserContext';



const CardsDetail = (props) => {
  
  const user = useContext(UserContext);
  console.log(user.role);
 

  return (
    <Link to={props.to} style={{ textDecoration: 'none' }}> 
    <Card className="cards-topo">
      <CardBody>
        <div className="d-flex align-items-center">
          <div  className={`circle-box md-box  ${props.bg}`} style={{ display: user.role === 'administrator' ? 'none' : 'block' }}>
            <i className={props.icon}></i>
          </div>
          <div className=" " style={{ display: user.role === 'subscriber' ? 'none' : 'block' }} >
           <h1 >{props.bg}</h1>
          </div>
          <div className="ms-3">
            <h5 className="text-card-topo">{props.earning}</h5>
            <p className="text-card-topo">{props.title}</p>
          </div>
        </div>
      </CardBody>
    </Card>
    </Link>
  );
};

export default CardsDetail;
