import React, { useState } from "react";
import {
  Card,
  Row,
  Col,
  CardTitle,
  CardBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import API from "../../services/axios/Requests"; // Certifique-se de que o caminho está correto
import { useTranslation } from 'react-i18next';

const Contato = () => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    assunto: '',
    mensagem: '',
    file: '',
  });

  const [modal, setModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (files) {
      setFormData(prevState => ({
        ...prevState,
        [name]: files[0],
      }));
    } else {
      setFormData(prevState => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const dataToSend = new FormData();
    Object.keys(formData).forEach(key => {
      dataToSend.append(key, formData[key]);
    });

    try {
      await API.post('/send-message', dataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setModalMessage(t('contato.messageSentSuccess'));
      setModal(true);
    } catch (error) {
      console.error(t('contato.messageSentError'), error);
      setModalMessage(t('contato.messageSentError'));
      setModal(true);
    }
  };

  const toggleModal = () => setModal(!modal);

  return (
    <Row>
      <Col>
        <Card>
          <CardBody>
            <CardTitle tag="h3" className="border-bottom p-3 mb-3">
              {t('contato.sendUsMessage')}
            </CardTitle>
            <Form onSubmit={handleSubmit} encType="multipart/form-data">
              <FormGroup className="mb-4">
                <Label for="assunto">{t('contato.subject')}</Label>
                <Input
                  id="assunto"
                  name="assunto"
                  placeholder={t('contato.enterSubject')}
                  type="text"
                  onChange={handleChange}
                />
              </FormGroup>
              <FormGroup className="mb-4">
                <Label for="mensagem">{t('contato.message')}</Label>
                <Input
                  id="mensagem"
                  name="mensagem"
                  type="textarea"
                  onChange={handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="exampleFile">{t('contato.attachDocument')}</Label>
                <Input
                  id="exampleFile"
                  name="file"
                  type="file"
                  onChange={handleChange}
                />
                <FormText>
                  {t('contato.attachDocumentHelp')}
                </FormText>
              </FormGroup>
              <Input
                id="user_id"
                name="user_id"
                type="hidden"
                value={'1'}
              />
              <Button className="mt-2" type="submit">{t('contato.sendMessage')}</Button>
            </Form>
          </CardBody>
        </Card>
      </Col>
      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>{t('contato.message')}</ModalHeader>
        <ModalBody>
          {modalMessage}
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={toggleModal}>{t('contato.close')}</Button>
        </ModalFooter>
      </Modal>
    </Row>
  );
};

export default Contato;
