import React, { useEffect, useState } from "react";
import { Card, CardBody, CardTitle, CardSubtitle } from "reactstrap";
import Chart from "react-apexcharts";
import API from "../../services/axios/Requests";
import { useTranslation } from 'react-i18next';

const SalesChart = () => {
  const { t } = useTranslation();
  const [chartData, setChartData] = useState([]);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await API.get('/chart-data');
        console.log("Dados do gráfico: ", response.data);
        const data = response.data;

        // Agrupar dados por categoria
        const groupedData = {};
        data.forEach(item => {
          if (!groupedData[item.category]) {
            groupedData[item.category] = [];
          }
          groupedData[item.category].push({ x: item.month, y: item.value });
        });

        // Extrair as categorias (meses)
        const categories = Object.keys(groupedData);
        setCategories(categories);

        // Preparar os dados para o gráfico
        const chartData = categories.map(category => ({
          name: category,
          data: groupedData[category]
        }));
        setChartData(chartData);
      } catch (error) {
        console.error("Erro ao buscar os dados do gráfico: ", error);
      }
    };

    fetchData();
  }, []);

  const chartOptions = {
    series: chartData,
    options: {
      chart: {
        type: "area",
      },
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        y: {
          formatter: function (value) {
            return `${value} %`; // Adiciona o símbolo '%' ao valor no tooltip
          }
        }
      },
      grid: {
        strokeDashArray: 3,
      },
      stroke: {
        curve: "smooth",
        width: 1,
      },
      xaxis: {
        categories: categories,
      },
    },
  };

  return (
    <Card>
      <CardBody>
        <CardTitle tag="h5">{t('salesChart.earnings')}</CardTitle>
        <CardSubtitle className="text-muted" tag="h6">
          {t('salesChart.earningsReport')}
        </CardSubtitle>
        <Chart
          type="area"
          width="100%"
          height="390"
          options={chartOptions.options}
          series={chartOptions.series}
        />
      </CardBody>
    </Card>
  );
};

export default SalesChart;
