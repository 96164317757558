import React, { useState, useContext } from 'react';
import { Card, CardBody } from 'reactstrap';
import { UserContext } from '../../hooks/UserContext';

const Topo = (props) => {
  // Estado para controlar se o saldo está visível
  const [isBalanceVisible, setIsBalanceVisible] = useState(false);
  const user = useContext(UserContext);

  // Função para alternar a visibilidade do saldo
  const toggleBalanceVisibility = () => {
    setIsBalanceVisible(!isBalanceVisible);
  };

  return (
    <Card className="topo">
      <CardBody style={{ display: user.role === 'administrator' ? 'none' : 'block' }}>
        <h3 className="mb-3">{props.title}</h3>
        <div className="d-flex align-items-center">
          <div className={`circle-box lg-box d-inline-block ${props.bg}`}>
            <i className={props.icon}></i>
          </div>
          <div className="ms-3">
            <div className="d-flex align-items-center">
              {/* Aqui nós alternamos o que é mostrado com base no estado isBalanceVisible */}
              <h3 className="mb-0 font-weight-bold">
                {isBalanceVisible ? props.earning : '...'}
              </h3>
              {/* Botão para alternar a visibilidade do saldo */}
              <button
                onClick={toggleBalanceVisibility}
                className="btn btn-link p-0 ms-2 eye-icon">
                <i className={`bi ${isBalanceVisible ? 'bi-eye' : 'bi-eye-slash'}`}></i>
              </button>
            </div>
            <small>{props.subtitle}</small>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default Topo;
