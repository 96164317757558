import React, { useState, useContext, useEffect } from "react";
import API from "../../services/axios/Requests";
import { NumericFormat } from 'react-number-format';
import { UserContext } from '../../hooks/UserContext'; // Importa o contexto do usuário
import {
  Card,
  Alert,
  Row,
  Col,
  CardTitle,
  CardBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  Spinner,
  InputGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import { useTranslation } from 'react-i18next';

const GetInvest = () => {
  const { t } = useTranslation();
  const [walletOrigin, setWalletOrigin] = useState(""); // Adiciona estado para o valor do input
  const [quantity, setQuantity] = useState(""); 
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState([]);
  const [walletValue, setWalletValue] = useState(null); 
  const [DateReristredValue, setDateRegistredValue] = useState(null); 
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  
  const user = useContext(UserContext); // Obtém o contexto do usuário

  // Função para buscar o valor da carteira do usuário
  const fetchWalletValue = async () => {
    try {
      const response = await API.get(`/wallet/${user.id}`); 
      setWalletValue(response.data.wallet.quantity);

      const response2 = await API.get(`/user/${user.id}`); 
      setDateRegistredValue(response2.data.created_at);
      
    } catch (error) {
      console.error(t('getInvest.fetchWalletError'), error);
    }
  };

  const calculateDaysSinceRegistration = (DateReristredValue) => {
    const registeredDate = new Date(DateReristredValue);
    const currentDate = new Date();
    const differenceInMillis = currentDate - registeredDate;
    const daysDifference = Math.floor(differenceInMillis / (1000 * 3600 * 24));
    return daysDifference;
  };

  const verify = () => {
    const daysSinceRegistration = calculateDaysSinceRegistration(DateReristredValue);
    if (daysSinceRegistration < 365) {
      setErrorMessage([t('getInvest.minimumAccountAgeError')]);
    } else if (daysSinceRegistration < 30 && walletValue < 40000) {
      setErrorMessage([t('getInvest.minimumBalanceError')]);
    } else {
      window.location.href = "/getinvest";
    }
  };

  useEffect(() => {
    const fetchWalletValue = async () => {
      try {
        const response = await API.get(`/wallet/${user.id}`);
        setWalletValue(response.data.wallet.quantity);

        const userResponse = await API.get(`/user/${user.id}`);
        setDateRegistredValue(userResponse.data.created_at);
        const daysSinceRegistration = calculateDaysSinceRegistration(userResponse.data.created_at);
        setIsButtonDisabled(daysSinceRegistration < 90 || (daysSinceRegistration < 30 && response.data.wallet.income < 30000));
      } catch (error) {
        console.error(t('getInvest.fetchWalletError'), error);
      }
    };

    fetchWalletValue();
  }, [user.id, t]);

  const MoneyIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-currency-dollar" viewBox="0 0 16 16">
      <path d="M4 10.781c.148 1.667 1.513 2.85 3.591 3.003V15h1.043v-1.216c2.27-.179 3.678-1.438 3.678-3.3 0-1.59-.947-2.51-2.956-3.028l-.722-.187V3.467c1.122.11 1.879.714 2.07 1.616h1.47c-.166-1.6-1.54-2.748-3.54-2.875V1H7.591v1.233c-1.939.23-3.27 1.472-3.27 3.156 0 1.454.966 2.483 2.661 2.917l.61.162v4.031c-1.149-.17-1.94-.8-2.131-1.718zm3.391-3.836c-1.043-.263-1.6-.825-1.6-1.616 0-.944.704-1.641 1.8-1.828v3.495l-.2-.05zm1.591 1.872c1.287.323 1.852.859 1.852 1.769 0 1.097-.826 1.828-2.2 1.939V8.73z"/>
    </svg>
  );

  // Função para lidar com o envio do formulário
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (parseFloat(quantity) > parseFloat(walletValue)) {
      setErrorMessage([t('getInvest.exceedBalanceError')]);
      setLoading(false);
      return; // Interrompe o envio do formulário
    }

    const dataToSend = new FormData();
    dataToSend.append('wallet_origin', walletOrigin);
    dataToSend.append('quantity', quantity);
    dataToSend.append('type_of', 'get_fundos_invest');
    dataToSend.append('user_id',  user.id);

    try {
      await API.post('/transaction', dataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setLoading(false);
      setModal(true); // Abre o modal de confirmação
      setWalletOrigin("");
      setQuantity("");
      setErrorMessage([]);
    } catch (error) {
      setLoading(false);
      if (error.response && error.response.data && error.response.data.errors) {
        // Extrai todas as mensagens de erro específicas para um array
        const errors = error.response.data.errors;
        const errorMessages = Object.keys(errors).flatMap(field => errors[field]);
        setErrorMessage(errorMessages);
      } else {
        setErrorMessage([t('getInvest.submitError')]);
      }
    }
  };

  // Função para alternar a visibilidade do modal
  const toggleModal = () => setModal(!modal);

  return (
    <Row>
      <Col>
        {/* Card-1 */}
        <Card>
          <CardTitle tag="h2" className="border-bottom p-3 mb-0">
            <i className='bi bi-arrow-down-left' style={{fontSize: '2rem', marginRight: '1rem'}}></i> {t('getInvest.requestInvestmentWithdrawal')}
          </CardTitle>
          <CardBody>
            {errorMessage.length > 0 && (
              <Alert color="danger">
                <ul>
                  {errorMessage.map((msg, index) => (
                    <li key={index}>{msg}</li>
                  ))}
                </ul>
              </Alert>
            )}

            <Row>
              <Col sm="12" lg="8">
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col sm="9" lg="9">
                      <FormGroup className="mb-4">
                        <Label for="quantityInput"><b>{t('getInvest.amount')}</b></Label>
                        <InputGroup>
                          <NumericFormat
                            id="quantity"
                            name="quantity"
                            placeholder={t('getInvest.amountPlaceholder')}
                            className="form-control"
                            value={quantity}
                            onValueChange={(values) => {
                              const { value } = values;
                              setQuantity(value);
                            }}
                            thousandSeparator="."
                            decimalSeparator=","
                            allowNegative={false}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            isNumericString={true}
                          />
                          <Button>
                            <MoneyIcon />
                          </Button>
                        </InputGroup>
                        <FormText>
                          {t('getInvest.totalInvestmentBalance')}: <strong>{walletValue !== null ? `${Number(walletValue).toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 })} USDT` : '...'}</strong>
                        </FormText>
                      </FormGroup>
                      <FormGroup>
                        <Label for="wallet_origin"><b>{t('getInvest.walletAddress')}</b></Label>
                        <InputGroup>
                          <Input 
                            id="wallet_origin"
                            name="wallet_origin"
                            placeholder={t('getInvest.walletAddressPlaceholder')}
                            type="text"
                            bsSize="lg"
                            value={walletOrigin}
                            onChange={(e) => setWalletOrigin(e.target.value)}
                          />
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <Col sm="12" lg="12">
                      <FormGroup check>
                        <Label check>
                          <Input type="checkbox" />{' '}
                          {t('getInvest.agree')}
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col sm="12" lg="4">
                      <br />
                      <Button type="submit" className="mt-2" color="success" size="lg" disabled={isButtonDisabled}>
                        {loading ? <Spinner size="sm" /> : t('getInvest.confirm')}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
      {/* Modal de confirmação */}
      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>{t('getInvest.confirmation')}</ModalHeader>
        <ModalBody>
          {t('getInvest.confirmationMessage')}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => { toggleModal(); window.location.href = "/transactions"; }}>{t('getInvest.close')}</Button>
        </ModalFooter>
      </Modal>
    </Row>
  );
};

export default GetInvest;
